import { notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { setAccessToken, setRefreshToken } from '../../accessToken';
import { DataContext } from '../../Context/DataContext';
import { useUpdateUserMutation } from '../../generated/graphql';
import { Button } from '../Button';
import Input from '../Input';
import styles from './user.module.scss';

interface UserSettingsProps {

}
type Field = {
  name: string
  type: string
  value: string
  placeholder: string
}

export const UserSettings: React.FC<UserSettingsProps> = () => {
  const [fields, setFields] = useState<Field[]>()
  const [hint, setHint] = useState(false);
  const { me } = useContext(DataContext);
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    // @ts-ignore
    const felder: Field[] = Object.entries(me).map((a) => { // eslint-disable-line
      if (a[0] !== '__typename' && a[0] !== 'role') {
        let type = 'text';
        let placeholder = a[0].charAt(0).toUpperCase() + a[0].slice(1);
        if (a[0] === 'dsgvo') {
          type = 'checkbox';
          placeholder = 'Do you accept our DSGVO?'
        }
        if (a[0] === 'id') {
          type = 'hidden';
        }
        if (a[0] === 'email') {
          type = 'email';
        }

        return { name: a[0], type, value: a[1], placeholder }
      }
    });
    setFields(felder.filter(f => f !== undefined));
  }, []) // eslint-disable-line

  const handleValue = (index, field, value) => {
    if (field.name === "email") {
      setHint(true);
    }
    const newField = { ...field, value }
    fields[index] = newField;
    setFields([...fields]);
  }


  const handleSave = async (e) => {
    e.preventDefault()
    const id = fields.find(f => f.name === 'id').value;
    const name = fields.find(f => f.name === 'name').value;
    const lastname = fields.find(f => f.name === 'lastname').value;
    const email = fields.find(f => f.name === 'email').value;
    const dsgvo = fields.find(f => f.name === 'dsgvo').value;
    const update = await updateUser({
      variables: {
        id: parseInt(id),
        name,
        lastname,
        email,
        dsgvo: Boolean(dsgvo)
      }
    })
    if (update.data.updateUser) {
      notification.success({
        message: 'Your account has been updated successfully!'
      })
      if (hint) {
        setAccessToken("");
        setRefreshToken("");
      }
      window.location.reload();
    } else {
      notification.error({
        description: 'Something went wrong!',
        message: 'Error, Ooops'
      })
    }
  }

  return (
    <form onSubmit={handleSave}>
      <div className={styles.editUser}>
        {fields && fields.map((field, i) => {
          return (
            <div key={i} className={field.type === 'hidden' ? styles.hidden : styles.field}>
              <Input
                type={field.type}
                name={field.name}
                value={field.value}
                required={true}
                setValue={(value) => handleValue(i, field, value)}
                placeholder={field.placeholder}
              />
            </div>
          )
        })}
        <div>
          {hint && (
            <p>
              <strong>Attention:</strong> If you change your email address, you can only log in after verifying your email address again.
            </p>
          )}
        </div>
        <div className={styles.saveContainer}>
          <Button label={"Save"} submit={true} selfAlign={false} />
        </div>
      </div>
    </form>
  );
}
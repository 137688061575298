import React, { Suspense } from 'react'
import { useSelectionsQuery, useOptionsQuery, useRemoveOptionMutation, useRemoveSelectionMutation } from '../../../generated/graphql';
import Loader from '../../../Components/Loader';
import { Selection } from '../Selection';
import { Options } from '../Options';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { notification } from 'antd';
import styles from './styles.module.scss';

export const Selector: React.FC = () => {
  const { data: selectionsData, refetch: refetchSelection } = useSelectionsQuery({ fetchPolicy: "network-only" });
  const { data: optionsData, refetch: refetchOptions } = useOptionsQuery({ fetchPolicy: "network-only" });
  const [removeType] = useRemoveOptionMutation();
  const [removeSelection] = useRemoveSelectionMutation();

  const removeOption = async optionId => {
    const removed = await removeType({
      variables: {
        id: optionId
      }
    });
    if (removed.data.removeOption) {
      notification.success({
        message: `Option successfully removed!`
      });
      refetchOptions();
    } else {
      notification.error({
        message: `Could not removed Option!`
      });
    }
  }

  const removeSelect = async selectionId => {
    const removed = await removeSelection({
      variables: {
        id: selectionId
      }
    });
    if (removed.data.removeSelection) {
      notification.success({
        message: `Select and Options successfully removed!`
      });
      refetchSelection();
      refetchOptions();
    } else {
      notification.error({
        message: `Could not removed Select!`
      });
    }
  }

  return (
    <Row gutter={[32, 32]} className={styles.row}>
      <Col span={32}>
        <Suspense fallback={<Loader />}>
          <Selection removeSelection={removeSelect} refetchSelection={refetchSelection} selectionsData={selectionsData} />
        </Suspense>
      </Col>
      <Col span={32}>
        <Suspense fallback={<Loader />}>
          {<Options removeOption={removeOption} optionsData={optionsData} refetchOptions={refetchOptions} selectionsData={selectionsData} />}
        </Suspense>
      </Col>
    </Row>
  );
}
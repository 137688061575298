import React, { useEffect, useState } from 'react';
import { Typography, Select } from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import styles from './introduction.module.scss';
import { SimpleFileEditor } from '../../../Components/Editors/SimpleFileEditor';
import { SimpleEditor } from '../../../Components/Editors/SimpleEditor';
import { Icon } from '../../../Components/Icon';
import { IntroductionStep, useUpdateStepMutation } from '../../../generated/graphql';

const { Paragraph } = Typography;

interface IntroCardProps {
  step: IntroductionStep
  remove: Function
}

export const IntroCard: React.FC<IntroCardProps> = ({ step, remove }) => {
  const [title, setTitle] = useState(step.title);
  const [subtitle, setSubtitle] = useState(step.subtitle);
  const [text, setText] = useState(step.text);
  const [image, setImage] = useState(step.image);
  const [icon, setIcon] = useState(step.icon);

  const [update] = useUpdateStepMutation()

  const icons = [
    "number-0",
    "number-1",
    "number-2",
    "number-3",
    "number-4",
    "number-5",
    "number-6",
    "number-7",
    "number-8",
    "number-9",
  ]

  const updateStuff = async () => {
    if (step.title !== title ||
      step.subtitle !== subtitle ||
      step.text !== text ||
      step.image !== image ||
      step.icon !== icon) {
      await update({
        variables: {
          id: step.id,
          title: title ? title : "",
          subtitle: subtitle ? subtitle : "",
          text: text ? text : "",
          image: image ? image : "",
          icon: icon ? icon : "",
        }
      });
    }
  }

  useEffect(() => {
    updateStuff();
  }, [title, subtitle, text, image, icon]) // eslint-disable-line

  const handleSelect = (val: string) => {
    setIcon(val)
  }

  return (
    <div className={styles.introCard}>
      <Paragraph className={styles.headline} editable={{
        onChange: setTitle,
      }}>
        {title}
      </Paragraph>
      <Paragraph className={styles.subheadline} editable={{
        onChange: setSubtitle,
      }}>
        {subtitle}
      </Paragraph>

      <SimpleFileEditor type={'images'} set={setImage} value={image} />

      <SimpleEditor classes={styles.text} content={text} set={setText} />

      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select an Icon"
        optionFilterProp="children"
        onChange={handleSelect}
        value={icon}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {icons.map((ic, i) => (
          <Select.Option key={i} value={ic}><Icon color={"#ccc"} size={25} name={ic} /></Select.Option>
        ))}
      </Select>

      <DeleteOutlined
        className={styles.remove}
        onClick={() => {
          remove(step.id);
        }}
        translate={"no"}
      />
    </div>
  );
}
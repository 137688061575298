import React, { useState } from 'react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import CKEditor from '@ckeditor/ckeditor5-react';
import config from './Config/config.json';

type Option = {
  model?: string,
  view?: string,
  title?: string,
  class?: string
}

interface EditorProps {
  defaultConfig?: {
    toolbar?: {
      items?: string[]
    },
    image?: {
      toolbar?: string[]
    }
    heading?: {
      options?: Option[],
    },
    language?: string,
    placeholder?: string,
    readOnly?: boolean
  },
  content: string,
  set: Function,
  classes?: string
}

export const SimpleEditor: React.FC<EditorProps> = ({ defaultConfig, content, set, classes }) => {
  const [editorData] = useState(content);

  let editorConfig = defaultConfig;

  if (!editorConfig) {
    editorConfig = config;
  }

  return (
    <div className={classes}>
      <CKEditor
        editor={BalloonEditor}
        data={editorData}
        config={editorConfig}
        onInit={(editor: EditorProps) => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          set(data);
        }}
      />
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined
} from '@ant-design/icons';
import styles from './fileEditor.module.scss';
import { Video } from '../Video';
import { Image } from '../Image';
import { Document } from '../Document';
import { Dummy } from '../Dummy';
import Modal from 'antd/es/modal';
import { FileHandler } from '../../Admin/Components/FileHandler';

interface SimpleFileEditorProps {
  type: string
  value: string
  set: Function
}

export const SimpleFileEditor: React.FC<SimpleFileEditorProps> = ({ type, value, set }) => {
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState<string>()
  let Expose;

  const handleContent = (data: string) => {
    setCurrent(data);
    set(data);
  }

  const handleRemove = () => {
    setCurrent(null)
    set(null)
  }

  useEffect(() => {
    if (!current) {
      setCurrent(value);
    }
    if (current && value === null) {
      setCurrent(null)
    }
  }, [value]) // eslint-disable-line

  if (type === 'images') {
    Expose = Image;
  }
  if (type === 'videos') {
    Expose = Video;
  }
  if (type === 'documents') {
    Expose = Document;
  }

  return (
    <div className={styles.editor}>
      {Expose && current ? (
        <>
          <Expose uri={current} name={current} />
          <div className={styles.close} onClick={handleRemove}><DeleteOutlined translate={'remove'} /></div>
        </>
      ) : (
          <Dummy open={() => setOpenModal(!openModal)} />
        )}

      <Modal
        title={`Select an Video File`}
        visible={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        centered
        width={1024}
      >
        <FileHandler type={type} setFile={handleContent} selected={current} />
      </Modal>
    </div>
  );
}
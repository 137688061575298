import React, { Suspense, useContext } from "react";
import styles from "./filter.module.scss";
import { useConfigurationsQuery, Configuration } from "../../generated/graphql";
import Loader from "../../Components/Loader";
import { DataContext } from "../../Context/DataContext";
import { Slider } from "../../Components/Slider";
import useDimensions from "../../Hooks/useDimensions";
import { Config } from "../../Components/Config";
import { useSpring, animated as a } from 'react-spring';

interface filterProps {
  isFiltered: boolean
}

const Configs = ({ items }: { items: Configuration[] }) => {
  return (
    <div className={styles.container}>
      {items && items.map(config => (
        <Config key={config.id} config={config} className={"swiper-slide"} />
      ))}
    </div>
  )
}

export const Filter: React.FC<filterProps> = ({ isFiltered }) => {
  const { selected } = useContext(DataContext);
  const { data } = useConfigurationsQuery();
  const { width } = useDimensions(window);
  let filtered;
  const ani = useSpring({
    height: isFiltered ? 360 : 0
  })

  if (data && selected) {
    filtered = data.configurations.filter(d => {
      const f = d.properties.map((val: any, i) => {
        return selected[val.name] === val.value;
      }).filter(i => i);

      return f.length === Object.keys(selected).length;
    })
  }

  return (
    <a.div style={{ height: ani.height, overflow: "hidden" }} className={styles.filterContainer}>
      <h2>Results for your Scenario:</h2>
      <Suspense fallback={<Loader />}>
        {width < 758 ? (
          <Slider items={filtered} slidesPerView={1} navigation={true} />
        ) : (
            <Configs items={filtered} />
          )}
        {filtered && filtered.length === 0 && (
          <>
            <div className={styles.text}>
              Nothing found, try another configuration.
            </div>
          </>
        )}
      </Suspense>
    </a.div>
  );
}
import React from 'react';
import { Link } from "react-router-dom";
import styles from './button.module.scss';

interface buttonProps {
  label: string
  path?: string
  onClick?: Function
  selfAlign?: boolean
  disabled?: boolean
  staticButton?: boolean
  submit?: boolean
}

export const Button: React.FC<buttonProps> = ({ path, label, onClick, selfAlign = true, disabled = false, submit = false, staticButton = false }) => {

  let buttonClass = selfAlign ? styles.button : styles.alignedButton;

  if (staticButton) {
    buttonClass = selfAlign ? styles.staticButton : styles.staticAlignedButton;
  }

  const handleClick = () => {
    onClick();
  }

  return (
    <>
      {!path && onClick && disabled && (
        <div className={styles.disabledButton}>
          {label}
        </div>
      )}
      {!path && onClick && !disabled && (
        <div className={buttonClass} onClick={handleClick}>
          {label}
        </div>
      )}
      {!path && !onClick && !disabled && submit && (
        <button className={buttonClass} type={"submit"}>
          {label}
        </button>
      )}
      {path && !onClick && !disabled && (
        <Link className={buttonClass} to={{ pathname: path }}>
          {label}
        </Link>
      )}
      {!path && !onClick && !submit && (
        <span className={styles.buttonError}>No Path or Clickhandler provided!</span>
      )}
    </>
  );
}
import React from 'react'
import { BaseComponent as Base } from '../Base';
import { ConfigurationComponent as Configuration } from '../Configuration';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import styles from './styles.module.scss';
import { useConfigurationsQuery, useBasesQuery } from '../../../generated/graphql';

export const Configurator: React.FC = () => {
  const { data: configurationData, refetch: refetchConfigs } = useConfigurationsQuery({ fetchPolicy: "network-only" });
  const { data: basesData, refetch: refetchBases } = useBasesQuery({ fetchPolicy: "network-only" });

  const removeBase = () => {
    refetchConfigs();
  }

  return (
    <Row gutter={[16, 16]} className={styles.row}>
      <Col span={24}>
        <Base basesData={basesData} refetch={refetchBases} handleBase={removeBase} />
      </Col>
      <Col span={24}>
        <Configuration configurationData={configurationData} refetch={refetchConfigs} />
      </Col>
    </Row>
  );
}
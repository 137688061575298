import React, { Suspense, useEffect, useState } from 'react';
import { Steps } from 'antd';
import styles from './introduction.module.scss';
import useDimensions from '../../Hooks/useDimensions';
import { Button } from '../Button';
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperClass from 'swiper/types/swiper-class';
import { Icon } from '../Icon';
import { useStepsQuery } from '../../generated/graphql';
import Loader from '../Loader';
import { Image } from '../Image';

const { Step } = Steps;

interface IntroductionProps {
  close: Function
}

export const Introduction: React.FC<IntroductionProps> = ({ close }) => {
  const { width, height } = useDimensions(window);
  const [current, setCurrent] = useState<number>(0);
  const [swiper, setSwiper] = useState(null);
  const { data } = useStepsQuery({ fetchPolicy: 'no-cache' });

  let mobile = false;

  if (width <= 400 && width < height) {
    mobile = true;
  }

  const handleNext = async () => {
    setCurrent(current + 1);
  }

  const handlePrev = async () => {
    setCurrent(current - 1);
  }

  const handleChange = (a) => {
    setCurrent(a.realIndex);
  }

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(current);
    }

  }, [current]) // eslint-disable-line

  const initSwiper = async () => {
    await swiper.init()
  }

  useEffect(() => {
    if (swiper && !swiper.initialized) {
      initSwiper()
    }
  }, [swiper]) //eslint-disable-line

  const params = {
    navigation: false,
    spaceBetween: 30,
    slidesPerView: 1,
    init: false,
    lazy: true,
    // autoHeight: true
  }

  return (
    <div className={styles.introduction}>
      <Suspense fallback={<Loader />}>
        <Swiper {...params} onSwiper={(sw) => setSwiper(sw)} onSlideChange={handleChange}>
          {data && data.steps.map((item, i) => (
            <SwiperSlide key={i} className={styles.slide}>
              <div className={styles.stepContent}>
                <h3>{item.title}</h3>
                <h4>{item.subtitle}</h4>

                <Image uri={item.image} name={item.title} classes={"swiper-lazy"} />
                <div className="swiper-lazy-preloader"></div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.buttons}>
          {current === 0 && (
            <>
              <div />
              <Button onClick={handleNext} selfAlign={false} label={"Next"} staticButton />
            </>
          )}
          {current > 0 && current < (data && data.steps.length - 1) && (
            <>
              <Button onClick={handlePrev} selfAlign={false} label={"Prev"} staticButton />
              <Button onClick={handleNext} selfAlign={false} label={"Next"} staticButton />
            </>
          )}
          {current > 0 && current === (data && data.steps.length - 1) && (
            <>
              <Button onClick={handlePrev} selfAlign={false} label={"Prev"} staticButton />
              <Button onClick={() => close()} selfAlign={false} label={"Done"} staticButton />
            </>
          )}
        </div>
        <div className={styles.stepsContainer}>
          <Steps current={current} direction={"horizontal"} labelPlacement={"horizontal"} className={styles.steps} size={!mobile ? "small" : "default"}>
            {data && data.steps.map((item, i) => (
              <Step key={i} title={null} subTitle={null} description={null} style={{ color: "#ff0000" }} icon={<Icon color={current === i ? "#00958a" : "#ccc"} size={25} name={item.icon} />} />
            ))}
          </Steps>
        </div>
      </Suspense>
      <div className={styles.hint}>
        <p className={styles.hintHead}>Hint:</p>
        <p>
          If you need to go back to this Introduction, you can always click on "Introduction" in the footer navigation and reopen it.
        </p>
      </div>
    </div>
  );
}
import React, { useState, SyntheticEvent, useRef, useEffect } from 'react';
import { animated as a, useSpring } from 'react-spring';
import { Spring } from 'react-spring/renderprops'
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import styles from "./styles.module.scss";
import { Info } from '../Info';

type Selector = {
  id: number
  label: string
  description: string
  sort: number
}

type OptionType = {
  id: number
  name: string
  sort: number
  selector: Selector
}

interface selectProps {
  options: OptionType[]
  label: string
  description: string
  setValue: Function
  selected?: string
}

export const Select: React.FC<selectProps> = ({ selected, options, label, description, setValue }) => {
  const { width, height } = useWindowDimensions();
  const labelRef = useRef<HTMLDivElement>(null);
  const [openSelect, setOpenSelect] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [defaultHeight, setDefaultHeight] = useState(width >= 768 ? 50 : 38);
  const [selectHeight, setSelectHeight] = useState(150);
  const [selectWidth, setSelectWidth] = useState(0);
  const optionsRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const [labelWidth, setLabelWidth] = useState<string>();
  let baseFontSize = 1.5;
  let foldFontSize = 1;

  if (width < 500 && width < height) {
    baseFontSize = 1;
    foldFontSize = 0.75;
  }

  const convertRemToPixels = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

  const hProps = useSpring({
    height: (openSelect) ? 0 : 35,
    paddingTop: (openSelect || !selected) ? 0 : convertRemToPixels(0.3),
    paddingLeft: (openSelect || !selected) ? 0 : convertRemToPixels(1),
    paddingBottom: (openSelect || !selected) ? 0 : convertRemToPixels(0.3),
    paddingRight: (openSelect || !selected) ? 0 : convertRemToPixels(1),
    config: { duration: 10 }
  })

  useEffect(() => {
    setDefaultHeight(width >= 768 ? 50 : 38)
  }, [width]) // eslint-disable-line

  useEffect(() => {
    handleLabelWidth();
  }, [labelRef]) // eslint-disable-line

  useEffect(() => {
    handleLabelWidth()
  }, [selected]); // eslint-disable-line

  const handleLabelWidth = () => {
    if (labelRef) {
      setLabelWidth(labelRef.current ? labelRef.current.offsetWidth + "px" : "auto");

      if (openSelect || selected) {
        setShowInfo(true);
      }
    }
  }

  const handleStart = () => {
    setShowInfo(false);
  }

  const handleSelection = (option: OptionType): void => {
    setValue(option.name, label);
    setOpenSelect(!openSelect);
  }

  const handleSelectOpen = (e: SyntheticEvent): void => {
    if (e.target === e.currentTarget) {
      setOpenSelect(!openSelect);
    }
  }

  const handleSelectClasses = (): string => {
    let classes = styles.select;

    if (selected && !openSelect) {
      classes = [styles.select, styles.activeSelect].join(" ");
    }

    if (selected && openSelect) {
      classes = [styles.select, styles.openSelect].join(" ");
    }

    if (!selected && openSelect) {
      classes = [styles.select, styles.openSelect].join(" ");
    }

    return classes;
  }

  const handleLabel = (): any => {
    let theLabel = "";
    if (!openSelect && selected && selected.length > 0) {
      theLabel = selected;
    }
    return theLabel;
  }

  useEffect(() => {
    if (openSelect && optionsRef && optionsRef.current) {
      const { offsetHeight, offsetWidth } = optionsRef.current;

      setSelectHeight(offsetHeight);
      setSelectWidth(offsetWidth)
    }
    if (!openSelect) {
      setSelectHeight(defaultHeight);
    }
  }, [openSelect, optionsRef.current]); // eslint-disable-line

  return (options ? (
    <div className={styles.selectContainer}>
      <Spring
        to={{
          top: (openSelect || selected) ? -30 : 2,
          fontSize: (openSelect || selected) ? convertRemToPixels(foldFontSize) : convertRemToPixels(baseFontSize)
        }}
        onRest={handleLabelWidth}
        onStart={handleStart}
      >
        {st => <div ref={labelRef} className={styles.label} style={st} onClick={handleSelectOpen}>{label} <Info text={description} classes={showInfo ? [styles.info, styles.activeInfo].join(" ") : styles.info} /></div>}
      </Spring>
      <div
        className={handleSelectClasses()}
        ref={selectRef}
        style={{
          height: openSelect ? `${selectHeight}px` : `${defaultHeight}px`,
          width: selectWidth > 0 ? `${selectWidth}px` : 'auto',
          minWidth: labelWidth
        }}
      >
        <a.div className={styles.label} style={{
          height: hProps.height,
          paddingTop: hProps.paddingTop,
          paddingLeft: hProps.paddingLeft,
          paddingBottom: hProps.paddingBottom,
          paddingRight: hProps.paddingRight
        }} onClick={handleSelectOpen}>
          {handleLabel()}
        </a.div>
        <div className={styles.options} ref={optionsRef} style={{
          width: selectWidth > 0 ? `${selectWidth}px` : 'auto',
        }}>
          {options.map((option, id) => (
            <div key={id} className={styles.option} onClick={() => handleSelection(option)}>{option.name}</div>
          ))}
        </div>
      </div>
    </div>
  ) : null);
}
import React, { useState } from 'react';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { Link } from 'react-router-dom';
import { SavedConfig, useRemoveSavedConfigsMutation, useSavedConfigsQuery, useSendConfigsMutation } from '../../generated/graphql';
import { notification, Table, Tooltip } from 'antd';
import styles from './mylist.module.scss';
import { Config } from './Config';
import { Button } from '../Button';
// import './table.css';

const largeBP: Breakpoint = 'lg';

const columns = [
  {
    title: 'Solution Name',
    dataIndex: 'name',
    render: text => (
      <Tooltip placement="topLeft" title={text}>
        {text}
      </Tooltip>
    ),
    width: "210px"
  },
  {
    title: 'Solution Settings',
    responsive: [largeBP],
    render: (item: SavedConfig) => <Config savedConfig={item} />
  },
  {
    title: '',
    dataIndex: 'uri',
    render: uri => <Link className={styles.button} to={{ pathname: `/selection/result/${uri}` }} target={'_blank'}>Open</Link>,
    width: "65px"
  },
];

export const MyList: React.FC = () => {
  const { data, loading, refetch } = useSavedConfigsQuery({ fetchPolicy: "network-only" });
  const [removeSavedConfigs] = useRemoveSavedConfigsMutation();
  const [sendConfigs] = useSendConfigsMutation()
  const [selected, setSelected] = useState<SavedConfig[]>(null)

  const rowSelection = {
    hideSelectAll: true,
    fixed: true,
    columnWidth: '30px',
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows.length > 0 ? selectedRows : null);
    },
    getCheckboxProps: record => ({
      name: record.name,
    }),
  };

  const handleRemove = async () => {
    const removed = await removeSavedConfigs({
      variables: {
        ids: selected.map(s => s.id)
      }
    })
    if (removed.data.removeSavedConfigs) {
      refetch();
      setSelected(null);
    }
  }

  const handleSend = async () => {
    const sent = await sendConfigs({
      variables: {
        ids: selected.map(s => (s.id))
      }
    });

    if (sent.data.sendConfigs) {
      notification.success({
        message: "Email successfully sent."
      })
    } else {
      notification.error({
        message: "Ooops, something went wrong."
      })
    }
  }

  return (data ?
    <>
      <Table
        rowKey={'id'}
        pagination={false}
        size={'small'}
        loading={loading}
        className={styles.table}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data.savedConfigs}
      />
      <div className={styles.buttons}>
        <Button selfAlign={false} disabled={!selected} label={'Send Selected'} onClick={handleSend} />
        <Button selfAlign={false} disabled={!selected} label={'Remove Selected'} onClick={handleRemove} />
      </div>
    </> : null
  );
}
import React, { FormEvent } from 'react';
import Input from '../Input';
import { useLoginMutation, MeDocument, MeQuery } from '../../generated/graphql';
import styles from "./styles.module.scss";
import { setAccessToken } from '../../accessToken';
import Loader from '../Loader';

const { useState } = React;

interface loginProps {
  close: any
}

const Login: React.FC<loginProps> = ({ close }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { data, loading, error }] = useLoginMutation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const response = await login({
      variables: {
        email,
        password
      },
      update: (store, { data }) => {
        if (!data) {
          return null;
        }

        store.writeQuery<MeQuery>({
          query: MeDocument,
          data: {
            me: data.login.user
          }
        });
      }
    });

    if (response && response.data) {
      localStorage.setItem('jid', response.data.login.refreshToken)
      setAccessToken(response.data.login.accessToken);
    }
    close()
  }

  return (
    <form className={styles.loginContainer} onSubmit={handleSubmit}>
      {!loading && !error && (
        <>
          <Input
            id="email"
            value={email}
            placeholder={"Your Email"}
            type={"email"}
            setValue={setEmail}
          />

          <Input
            id="pass"
            value={password}
            placeholder={"Your Password"}
            type={"password"}
            setValue={setPassword}
          />
          {error && (
            <div className={styles.error}>Login Failed, please try again.</div>
          )}
          <button
            type={"submit"}
            className={styles.button}
            onClick={handleSubmit}
          >
            Login
          </button>
        </>
      )}
      {loading && !data && <Loader />}
      {!loading && !!data && !error && <div>You aree now logged in</div>}
      {!loading && error && (
        <div>Ooops, something went wrong.</div>
      )}
    </form>
  );
}

export default Login;
import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Link, RouteComponentProps } from 'react-router-dom';
import { ReactComponent as Logo } from '../logo.svg';
import { Dashboard } from './Components/Dashboard';
import { Selector } from './Components/Selector';
import { Configurator } from './Components/Configurator';
import { Files } from './Components/Files';
import { User } from './Components/User';
import { Introduction } from './Components/Introduction';
import Login from '../Components/Login';
import {
  SettingOutlined,
  SettingFilled,
  LogoutOutlined,
  DashboardOutlined,
  FileImageOutlined,
  DownSquareOutlined,
  UserOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import Button from 'antd/es/button';
import Layout from 'antd/es/layout';
import Menu from 'antd/es/menu';
import Modal from 'antd/es/modal';
import styles from './styles.module.scss';
import { getAccessToken, setAccessToken } from '../accessToken';
import { useLogoutMutation } from '../generated/graphql';
import { DataContext } from '../Context/DataContext';
import { Popover } from 'antd';
import { Settings } from './Settings';

const { Header, Sider } = Layout;

export const Admin: React.FC<RouteComponentProps> = ({ location }) => {
  const { me } = useContext(DataContext);
  const [collapsed, setCollapsed] = useState<boolean>(localStorage.getItem("collapsed") ? (localStorage.getItem("collapsed") === 'true') : false);
  const [logout, { client }] = useLogoutMutation();
  const [openLogin, setOpenLogin] = useState(true);
  const isLoggedIn: boolean = getAccessToken().length > 0 ? true : false;

  useEffect(() => {
    if (me && me.role !== "Admin") {
      setOpenLogin(true)
    }
    if (!me) {
      setOpenLogin(true)
    }
  }, [me]);

  const handleCollapse = (c) => {
    localStorage.setItem("collapsed", c);
    setCollapsed(c)
  }

  return (
    <Layout>
      {me && me.role === "Admin" && (
        <>
          <Sider collapsible collapsed={collapsed} onCollapse={(c) => handleCollapse(c)} className={styles.sider}>
            <div className={styles.logo} ><Link to={{ pathname: '/' }}><Logo fill={"#fff"} color={"#fff"} /></Link></div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
              <Menu.Item key="/admin" icon={<DashboardOutlined translate={"dashboard"} />}>
                <Link to={{ pathname: '/admin' }}>Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="/admin/selectors" icon={<DownSquareOutlined translate={"selectors"} />}>
                <Link to={{ pathname: '/admin/selectors' }}>Selectors</Link>
              </Menu.Item>
              <Menu.Item key="/admin/configurations" icon={<SettingOutlined translate={"configurations"} />}>
                <Link to={{ pathname: '/admin/configurations' }}>Configurations</Link>
              </Menu.Item>
              <Menu.Item key="/admin/files" icon={<FileImageOutlined translate={"files"} />}>
                <Link to={{ pathname: '/admin/files' }}>Files</Link>
              </Menu.Item>
              <Menu.Item key="/admin/users" icon={<UserOutlined translate={"users"} />}>
                <Link to={{ pathname: '/admin/users' }}>Users</Link>
              </Menu.Item>
              <Menu.Item key="/admin/introduction" icon={<InfoCircleOutlined translate={"introduction"} />}>
                <Link to={{ pathname: '/admin/introduction' }}>Introduction</Link>
              </Menu.Item>
            </Menu>
            <div className={styles.settings}>
              <Popover placement="rightBottom" title={"Settings"} content={<Settings />} trigger="click">
                <Button type="primary" shape="circle" className={styles.settingsButton} icon={<SettingFilled className={styles.settingsIcon} translate={'no'} />} />
              </Popover>
            </div>
          </Sider>
          <Layout className={styles.contentLayout}>
            <Header className={styles.header} style={{ padding: 0 }}>
              <Button
                type="primary"
                className={styles.logout}
                shape="circle"
                onClick={async () => {
                  await logout();
                  setAccessToken("");
                  await client!.resetStore();
                }}
                icon={<LogoutOutlined translate={'logout'} />}
              />
            </Header>
            <Switch location={location} >
              <Route exact path='/admin' component={Dashboard} />
              <Route exact path='/admin/selectors' component={Selector} />
              <Route exact path='/admin/configurations' component={Configurator} />
              <Route exact path='/admin/files' component={Files} />
              <Route exact path='/admin/users' component={User} />
              <Route exact path='/admin/introduction' component={Introduction} />
            </Switch>
          </Layout>
        </>
      )}
      {!isLoggedIn && (
        <Modal
          title="Administration Login #1"
          visible={openLogin}
          footer={null}
        >
          <Login close={() => setOpenLogin(false)} />
        </Modal>
      )}
      {me && me.role !== "Admin" && (
        <Modal
          title="Administration Login #2"
          visible={true}
          footer={null}
        >
          <div className={styles.errorRole}>
            <p>Sie haben keine Berechtigung für die Administration!</p>
            <Link to={{ pathname: "/" }}>Zurück zur Startseite</Link>
          </div>
        </Modal>
      )}
    </Layout >
  );
}

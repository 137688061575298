import React from "react";
const { useEffect, useRef } = React;

interface viewProps {
  images: [string] | null
}

const Viewer: React.FC<viewProps> = ({ images }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  let loadedImages: HTMLImageElement[] = [];
  let xInitial: any = null;
  let xLast: any = null;
  let currentImage: number = 0;
  let animation: any = null;
  let newFrame: any = null;

  const loadImages = (canvas: HTMLCanvasElement) => {
    if (images) {
      return new Promise(resolve => {
        images.forEach((image, index) => {
          const img: HTMLImageElement = new Image();
          img.src = image;
          img.addEventListener(
            "load",
            () => {
              index === 0 && setCanvasHeight(img, canvas);
              // Add the loaded image to the array to be used later.
              loadedImages.push(img);

              // Resolve the promise, if all the images have been loaded. Otherwise, draw the loading bar to show the progress.
              if (loadedImages.length === images.length) {
                resolve();
              } else {
                drawLoader((loadedImages.length * 100) / images.length);
              }
            },
            false
          );
        });
      });
    }
    return new Promise(resolve => {

    });
  }

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      const canvas: HTMLCanvasElement = canvasRef.current;
      canvas.width = canvas.clientWidth;
      canvas.height = 576; // initial default height

      // Pre-load all product images and then add the event listeners to the canvas and draw the first product image.
      loadImages(canvas).then(() => {
        canvas.addEventListener("mousedown", handleMouseDown, false);
        canvas.addEventListener("touchstart", handleTouchStart, false);
        canvas.addEventListener("mousemove", handleMouseMove, false);
        canvas.addEventListener("touchmove", handleTouchMove, false);
        canvas.addEventListener("mouseup", handleMouseUp, false);
        canvas.addEventListener("touchend", handleMouseUp, false);
        canvas.addEventListener("mouseout", handleMouseUp, false);

        drawImage(0, canvas);
      });
    }
    return () => {
      const canvas: HTMLCanvasElement = canvasRef.current; // eslint-disable-line

      // Remove all event listeners before the component gets removed from the page.
      canvas.removeEventListener("mousedown", handleMouseDown, false);
      canvas.removeEventListener("touchstart", handleTouchStart, false);
      canvas.removeEventListener("mousemove", handleMouseMove, false);
      canvas.removeEventListener("touchmove", handleTouchMove, false);
      canvas.removeEventListener("mouseup", handleMouseUp, false);
      canvas.removeEventListener("touchend", handleMouseUp, false);
      canvas.removeEventListener("touchend", handleMouseUp, false);
      canvas.removeEventListener("mouseout", handleMouseUp, false);
    }
  }, []) // eslint-disable-line

  const setCanvasHeight = (img: HTMLImageElement, canvas: HTMLCanvasElement) => {
    // const canvas: any = canvasRef.current;
    canvas.height = canvas.width;
    // calculation for 16:9 images!
    // (((canvas.clientWidth * 100) / img.height) * img.width) / 100;
  }

  const drawLoader = (progress: number) => {
    if (canvasRef && canvasRef.current) {
      const canvas: HTMLCanvasElement = canvasRef.current;
      const context = canvas.getContext("2d");
      const start = 1.5 * Math.PI;
      const circlePosX = canvas.clientWidth / 2;
      const circlePosY = canvas.clientHeight / 2;
      const diff: any = (progress * Math.PI * 2 * 10).toFixed(2);
      const position = diff / 1000 + start;

      context.clearRect(0, 0, canvas.width, canvas.height);

      context.lineWidth = 20;
      context.strokeStyle = "#00958a";
      context.fillStyle = "#00958a";
      context.textAlign = "center";
      context.font = "15px Helvetica";
      context.lineCap = "round";
      context.fillText(
        progress.toFixed(1) + "%",
        circlePosX + 2,
        circlePosY + 4,
        canvas.clientWidth
      );
      context.beginPath();
      context.arc(circlePosX, circlePosY, 50, start, position + 1);
      context.stroke();
    }
  }

  const drawImage = (frame: any, canvas) => {
    if (images) {
      // const canvas: any = canvasRef.current;
      const context = canvas.getContext("2d");

      // Clear the canvas before starting to draw
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Get the image element to draw on canvas from the pre-loaded images array.
      const newImage: any = loadedImages.filter(
        img => {
          return img.src === images[frame]
        }
      )[0];

      // console.log(loadedImages, images);

      // Resize the image depending on the canvas's size.
      const imageSizeScale = newImage.width / newImage.height;
      let newWidth = canvas.width;
      let newHeight = newWidth / imageSizeScale;

      if (newHeight > canvas.height) {
        newHeight = canvas.height;
        newWidth = newHeight * imageSizeScale;
      }

      // Draw the image on canvas
      context.drawImage(newImage, 0, 0, newWidth, newHeight);
      currentImage = frame;
    }
  }

  const handleMouseDown = (event: MouseEvent) => {
    xInitial = event.pageX;
  };

  const handleTouchStart = (event: TouchEvent) => {
    xInitial = event.touches[0].pageX;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (xInitial !== null) {
      const delta = event.pageX - (!xLast ? xInitial : xLast);
      xLast = event.pageX;

      let startingFrame = currentImage;
      if (currentImage === loadedImages.length - 1) {
        startingFrame = 0;
      } else if (currentImage === 0) {
        startingFrame = loadedImages.length - 1;
      }

      let moveFrame = startingFrame;
      if (delta > 0) {
        moveFrame = startingFrame - 1;
      } else if (delta < 0) {
        moveFrame = startingFrame + 1;
      }

      newFrame = Math.min(
        Math.max(moveFrame, 0),
        loadedImages.length - 1
      );

      if (animation === null) {
        animation = requestAnimationFrame(animationFrame);
      }
    }
  };

  const animationFrame = () => {
    drawImage(newFrame, canvasRef.current);
    animation = requestAnimationFrame(animationFrame);
  };

  const handleTouchMove = (event: any) => handleMouseMove(event.touches[0]);

  const handleMouseUp = () => {
    xInitial = null;
    xLast = null;
    animation && cancelAnimationFrame(animation);
    animation = null;
  };

  return <canvas ref={canvasRef} />;
}

export default Viewer;

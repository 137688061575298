import React from 'react';
import {
  SortableElement,
} from 'react-sortable-hoc';
import styles from './sortable.module.scss';
import { DragHandle } from './Handle';

export const SortableItem = SortableElement(({ children, align }) => {
  const editorStyle = align.length > 0 ? styles[align] : styles['left'];
  return (
    <div className={editorStyle}>
      <DragHandle />
      {children}
    </div>
  )
});
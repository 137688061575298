import React, { useState } from 'react'
import styles from './styles.module.scss';
import { Collection, useRemoveCollectionMutation } from '../../../generated/graphql';
import Modal from 'antd/es/modal';
import {
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { notification } from 'antd';
import Popconfirm from 'antd/es/popconfirm';
import { Image } from '../../../Components/Image';

interface FileProps {
  collection?: Collection
  refetch: Function
}

export const CollectionComponent: React.FC<FileProps> = ({ collection, refetch }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [removeCollection] = useRemoveCollectionMutation()

  const closeModal = () => {
    setOpen(!open);
  }

  const handleRemove = async (collection: string | null) => {
    if (collection) {
      const removedCollection = await removeCollection({
        variables: {
          collection
        }
      })
      if (removedCollection.data.removeCollection) {
        notification.success({
          message: `Collection successfully removed!`
        });
        refetch()
      } else {
        notification.error({
          message: `Could not remove Collection!`
        });
      }
    }
  }

  return (
    <>
      {collection && (
        <div className={styles.item}>
          <div className={styles.collection}>
            <Image uri={collection.image} name={collection.name} onClick={() => setOpen(true)} />
            <Popconfirm
              placement="left"
              title={"Are you sure?"}
              onConfirm={() => handleRemove(collection.name)}
              icon={<ExclamationCircleOutlined translate={"confirm"} style={{ color: 'red' }} />}
              okText="Yes"
              cancelText="No"
            >
              <span className={styles.remove}>
                <DeleteOutlined translate="delete" />
              </span>
            </Popconfirm>
          </div>
          <Modal
            title={collection.name}
            centered
            destroyOnClose={true}
            visible={open}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            footer={null}
            width={1024}
            className={styles.modal}
          >
            <Image uri={collection.image} name={collection.name} />
          </Modal>
          <span className={styles.label}>{collection.name}</span>
        </div>
      )}
    </>
  );
}
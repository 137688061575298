import React from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './styles.module.scss'
import { Config } from '../Config';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

interface indexProps {
	items: any
	slidesPerView: number
	navigation: boolean
}

export const Slider: React.FC<indexProps> = ({ items, slidesPerView, navigation }) => {
	const params = {
		navigation,
		spaceBetween: 30,
		slidesPerView
	}

	return (
		<div className={styles.swipeContainer}>
			<Swiper {...params}>
				{items && items.map(config => (
					<SwiperSlide key={config.id}>
						<Config config={config} className={"swiper-slide"} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

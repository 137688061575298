import React from 'react'
import { Properties } from '../../../generated/graphql';

import styles from './settings.module.scss';

interface SettingsProps {
  properties: Properties[]
}

export const Settings: React.FC<SettingsProps> = ({ properties }) => {
  return (
    <div className={styles.settings}>
      {properties.map((prop, i) => (
        <div key={i}><strong>{prop.name}:</strong> {prop.value}</div>
      ))}
    </div>
  );
}
import React, { Suspense, useContext } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Footer from "../../Components/Footer";
import { useConfigurationsQuery, Configuration } from "../../generated/graphql";
import Loader from "../../Components/Loader";
import { DataContext } from "../../Context/DataContext";
import { Slider } from "../../Components/Slider";
import useDimensions from "../../Hooks/useDimensions";
import { Config } from "../../Components/Config";

interface props {
  selections: string
}

const Configs = ({ items }: { items: Configuration[] }) => {
  return (
    <div className={styles.container}>
      {items && items.map(config => (
        <Config key={config.id} config={config} className={"swiper-slide"} />
      ))}
    </div>
  )
}

const Filter: React.FC<RouteComponentProps & props> = ({ history }) => {
  const { selected } = useContext(DataContext);
  const { data } = useConfigurationsQuery();
  const { width } = useDimensions(window);
  let filtered;

  if (data && selected) {
    filtered = data.configurations.filter(d => {
      const f = d.properties.map((val: any, i) => {
        return selected[val.name] === val.value;
      }).filter(i => i);

      return f.length === Object.keys(selected).length;
    })
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <img className={styles.bgImg} src="/vitaris_bg.jpg" alt="background" />

        <div className={styles.headlineContainer}>
          <h1>Results of your Search</h1>
          <p>Select to build your Solution!</p>
          <Suspense fallback={<Loader />}>
            {width < 758 ? (
              <Slider items={filtered} slidesPerView={1} navigation={true} />
            ) : (
                <Configs items={filtered} />
              )}
            {filtered && filtered.length === 0 && (
              <>
                <div className={styles.text}>
                  Nothing found, try another configuration.
                  <Link to={{ pathname: '/selection' }} className={styles.button}>Back</Link>
                </div>
              </>
            )}
          </Suspense>
        </div>
      </div>
      <Footer refresh={() => history.push("/")} />
    </div >
  );
};

export default Filter;

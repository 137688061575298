import React, { Suspense, useEffect, useState } from 'react'
import { useFilesByTypeQuery } from '../../generated/graphql';
import Loader from '../../Components/Loader';
import styles from './filehandler.module.scss';
import { Video } from '../../Components/Video';
import { Image } from '../../Components/Image';
import { Document } from '../../Components/Document';
import Input from 'antd/es/input';

interface FileHandlerProps {
  type: string
  setFile: Function
  selected?: string
}

export const FileHandler: React.FC<FileHandlerProps> = ({ type, setFile, selected }) => {
  const [query, setQuery] = useState<string>(null);
  const [files, setFiles] = useState(null);
  let Expose;
  const { data } = useFilesByTypeQuery({
    variables: {
      type
    },
    fetchPolicy: "network-only"
  });

  if (data && data.filesByType.length > 0) {
    if (type === 'images') {
      Expose = Image;
    }
    if (type === 'videos') {
      Expose = Video;
    }
    if (type === 'documents') {
      Expose = Document;
    }
  }

  useEffect(() => {
    if (!query && data && data.filesByType) {
      setFiles(data.filesByType);
    }
  }, [data]) // eslint-disable-line

  useEffect(() => {
    if (query && query.length > 0) {
      const filtered = data.filesByType.filter(f => {
        return clearFileName(clearFileNameWithPath(f)).includes(query)
      })
      setFiles([...filtered]);
    } else if (files && files.length !== data.filesByType) {
      setFiles(data.filesByType);
    }
  }, [query]) // eslint-disable-line

  const clearFileNameWithPath = (file) => {
    if (file.includes('../web/public')) {
      return file.replace('../web/public', '');
    } else if (file.includes(`../vitaris.visual-pool.de`)) {
      return file.replace(`../vitaris.visual-pool.de`, '');
    } else {
      return file;
    }
  }

  const clearFileName = (file) => {
    if (file.includes(`/files/${type}/`)) {
      return file.replace(`/files/${type}/`, '');
    } else {
      return file;
    }
  }

  const handleQuery = (e) => {
    setQuery(e.currentTarget.value);
  }

  return (
    <div>
      <div>
        <Input
          size={"large"}
          name={`query-${type}`}
          id={`query-${type}`}
          placeholder={`Filter ${type}`}
          allowClear={true}
          onChange={handleQuery}
          value={query}
          className={styles.filter}
        />
      </div>
      <div className={styles.fileHandler}>
        <Suspense fallback={<Loader />}>
          {files && files.length > 0 ? files.map((file, index) => (
            <div key={`file-${index}`}>
              {Expose && (
                <Expose
                  selected={selected === file}
                  uri={clearFileNameWithPath(file)}
                  name={file}
                  onClick={() => setFile(clearFileNameWithPath(file))}
                />
              )}
              <span className={styles.label}>{clearFileName(clearFileNameWithPath(file))}</span>
            </div>
          )) : (
              <p>No Files Found!</p>
            )}
        </Suspense>
      </div>
    </div>
  );
}
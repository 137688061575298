import React, { useCallback, useState, ChangeEvent } from 'react';
import axios from "axios";
import { useDropzone } from 'react-dropzone'
import Input from 'antd/es/input';
import styles from './styles.module.scss';

interface UploadProps {
  active: string
  setUploading: Function
  setProgress: Function
  refetch: Function
}

const backendHost = process.env.NODE_ENV === 'development' ? "https://localhost:4000/" : "https://api-vitaris.visual-pool.de/";

export const Upload: React.FC<UploadProps> = ({ active, setUploading, setProgress, refetch }) => {
  const [collection, setCollection] = useState("");
  let activeUpload: boolean = true;

  const axiosOptions = {
    onUploadProgress: (event: any) => {
      const { loaded, total } = event;
      const percent = Math.floor(loaded * 100 / total);

      if (percent < 100) {
        setProgress(percent);
      }
    }
  }

  const handleCol = (e: ChangeEvent<HTMLInputElement>) => {
    setCollection(e.target.value);
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (activeUpload) {
      // console.log(acceptedFiles);
      const formData = new FormData();
      setUploading(true);
      acceptedFiles.forEach((f: File, i: number) => {
        formData.append(`file${i}`, f);
      });

      if (active === '1' && collection.length > 0) {
        formData.append("collection", collection);
      }

      // uploadFiles({
      //   variables: {
      //     files: acceptedFiles
      //   }
      // });

      axios.post(`${backendHost}upload/files`, formData, axiosOptions).then(res => {
        setProgress(100);
        refetch();
        setTimeout(() => {
          return setUploading(false);
        }, 1000);
      }).catch(console.warn)
    }
  }, [activeUpload, active, collection, axiosOptions, refetch]); // eslint-disable-line

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (active === '1') {
    if (collection.length === 0) {
      activeUpload = false;
    } else {
      activeUpload = true;
    }
  } else {
    if (collection.length > 0) {
      setCollection(''); // reset collection
    }
  }

  return (
    <div className={styles.upload}>
      {active === '1' && <Input size="large" className={styles.input} id="collection" name="collection" placeholder="Collection Name" onChange={handleCol} value={collection} />}
      {activeUpload && (
        <div {...getRootProps({
          className: isDragActive ? [styles.dropzone, styles.active].join(" ") : styles.dropzone
        })}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <span>Drop the files here ...</span> :
              <span>Drag 'n' drop some files here, or click to select files</span>
          }
        </div>
      )}
      {!activeUpload && (
        <div className={[styles.dropzone, styles.inactive].join(' ')}>
          <span>To create and upload a Collection insert a <strong>Collection Name</strong>!</span>
        </div>
      )}
    </div>
  );
}
import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../../logo.svg";
import { setAccessToken, getAccessToken } from "../../../accessToken";
import { useLogoutMutation, useMeQuery } from "../../../generated/graphql";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import { Introduction } from "../../../Components/Introduction";

const Footer: React.FC = () => {
  const [logout, { client }] = useLogoutMutation();
  const [role, setRole] = useState("");
  const [introduction, setIntroduction] = useState(false);
  const { data } = useMeQuery();
  const [viewed, setViewed] = useState(localStorage.getItem("viewed") === "true" ? true : false);
  const token = getAccessToken();

  useEffect(() => {
    if (data && data.me && data.me.role) {
      setRole(data.me.role)
    }
    return () => setRole("")
  }, [data])

  const handleIntroduction = async () => {
    if (!viewed) {
      localStorage.setItem('viewed', "true");
      await setViewed(true);
      await setIntroduction(false);
    } else {
      setIntroduction(!introduction)
    }
  }

  return (
    <div className={styles.baseFooter}>
      <div className={styles.footerlinks}>
        <ul>
          <li>About</li>
          <li>Help</li>
          <li>Imprint</li>
          <li>Privacy policy</li>
          <li onClick={handleIntroduction}>Introduction</li>
          {role && role === "Admin" && (
            <li><Link to={{ pathname: "/admin" }}>admin</Link></li>
          )}
          {!!token && (<li
            onClick={async () => {
              await logout();
              setAccessToken("");
              await client!.resetStore();
            }}
          >
            logout
          </li>)}

        </ul>
      </div>
      <Link className={styles.footerLogoContainer} to={{ pathname: "/" }}>
        <Logo className={styles.footerLogo} />
      </Link>

      <Modal
        title={false} // eslint-disable-line
        visible={viewed === false ? true : introduction}
        centered
        destroyOnClose={true}
        maskClosable={true}
        maskStyle={{ backgroundColor: 'rgba(0, 41, 38, 0.7)' }}
        closable={true}
        onCancel={() => handleIntroduction()}
        footer={null}
        bodyStyle={{ padding: '0rem' }}
      >
        <Introduction close={() => handleIntroduction()} />
      </Modal>
    </div>
  );
};

export default Footer;

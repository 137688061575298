import { useState, useEffect } from 'react';

function getDimensions(element) {
  const { innerWidth: width, innerHeight: height } = element;
  return {
    width,
    height
  };
}

export default function useDimensions(element) {
  const [windowDimensions, setWindowDimensions] = useState(getDimensions(element));

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getDimensions(element));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // eslint-disable-line

  return windowDimensions;
}
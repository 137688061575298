import React from 'react'
import { useThumbnailQuery } from '../../generated/graphql';
import { Image } from '../Image';
import styles from './styles.module.scss';

interface DocumentProps {
  uri: string
  name: string
  selected?: boolean
  onClick?: any
  hidden?: boolean
}

export const Document: React.FC<DocumentProps> = ({ uri, name, selected, onClick, hidden }) => {
  const { data } = useThumbnailQuery({ variables: { file: uri } })

  const clearFileName = (file: string) => {
    if (file.includes('../web/public')) {
      return file.replace('../web/public', '');
    } else if (file.includes('../vitaris.visual-pool.de')) {
      return file.replace('../vitaris.visual-pool.de', '');
    } else {
      return file;
    }
  }

  return (data && data.thumbnail ? (
    <div className={styles.item}>
      <div className={styles.file}>
        <Image uri={data.thumbnail} name={data.thumbnail} selected={selected} onClick={onClick} />
      </div>
    </div>
  ) : (
      <div>
        <span className={styles.label}>{clearFileName(uri)}</span>
      </div>
    ));
}
import React, { useState } from 'react';
import { InfoCircleOutlined } from "@ant-design/icons";
import Modal from 'antd/es/modal';

interface InfoProps {
  classes: string
  text: string
}

export const Info: React.FC<InfoProps> = ({ classes, text }) => {
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <div onClick={() => setOpenInfo(!openInfo)} className={classes}>
      <InfoCircleOutlined translate={"info"} />
      <Modal
        title={"Info"}
        visible={openInfo}
        centered
        destroyOnClose={true}
        maskClosable={true}
        maskStyle={{ backgroundColor: 'rgba(0, 41, 38, 0.7)' }}
        closable={true}
        onCancel={() => setOpenInfo(false)}
        footer={null}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Modal>
    </div >
  );
}
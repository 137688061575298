import React from 'react'
import { Content } from '../../generated/graphql';
import { Image } from '../Image';
import styles from './styles.module.scss';
import { Video } from '../Video';

interface contentElementProps {
  element: Content
}

export const ContentElement: React.FC<contentElementProps> = ({ element }) => {
  return (
    <>
      {(element.type === 'text' || element.type === 'headline') && <div className={styles[element.align]} dangerouslySetInnerHTML={{ __html: element.content }} />}
      {element.type === 'images' && <div className={styles[element.align]}><Image uri={element.content} name={element.id} /></div>}
      {element.type === 'videos' && <div className={styles[element.align]}><Video uri={element.content} name={element.id} /></div>}
    </>
  );
}
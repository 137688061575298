import React, { useState, useEffect } from "react";
import { setAccessToken, setRefreshToken } from "./accessToken";
import Loader from "./Components/Loader";
import "./App.css";
import { Browser } from "./Browser";


interface Props {
  backendHost: string
}

export const App: React.FC<Props> = ({ backendHost }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(`${backendHost}refresh_token`, {
      method: "POST",
      credentials: "include",
      headers: myHeaders,
      body: JSON.stringify({ jid: localStorage.getItem('jid') })
    }).then(async x => {
      const { accessToken, refreshToken } = await x.json();
      if (refreshToken === "") {
        setAccessToken("");
      } else {
        setAccessToken(accessToken);
      }
      setRefreshToken(refreshToken);
      setLoading(false);
    });
  }, []); // eslint-disable-line

  if (loading) {
    return <div className="loadingContainer"><Loader /></div>;
  }

  return <Browser />;
};

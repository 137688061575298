import React, { SyntheticEvent } from "react";
import Viewer from "./Viewer";
import styles from "./main.module.scss";

const { useEffect, useState } = React;

interface threeDProps {
  path: string
}
// @TODO: Localhost vs local IP
const backendHost = process.env.NODE_ENV === 'development' ? "https://192.168.2.106:4000/" : "https://api-vitaris.visual-pool.de/";

const ThreeD: React.FC<threeDProps> = ({ path }) => {
  const [isLoading, setLoader] = useState(false);
  const [images, setImages] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoader(true);
    setError(null)
    fetch(`${backendHost}viewFiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ path })
    }).then(async x => {
      setImages(await x.json());
      setLoader(false);
    }).catch(err => setError(err));
  }, [path]); // eslint-disable-line

  const handleDoubleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    console.log("Double")
  }

  return (true ?
    <div className={styles.mainView} onDoubleClick={handleDoubleClick}>
      {!isLoading && !!images && !error ?
        (
          <Viewer images={images} />
        ) : (
          <div className={styles.placeholder} />
        )
      }
    </div>
    : null);
};

export default ThreeD;

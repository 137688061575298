import React from 'react'

interface videoProps {
  uri: string
  name: string
  selected?: boolean
  onClick?: Function
}

export const Video: React.FC<videoProps> = ({ uri, name, selected, onClick }) => {
  const selectedStyle = selected ? {
    border: "3px solid #1890ff"
  } : {
      border: "3px solid #fff"
    }
  return (
    <video style={selectedStyle} controls src={uri} title={name} onClick={(e) => {
      e.preventDefault();
      if (onClick) {
        onClick();
      }
    }} />
  );
}
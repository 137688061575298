import React, { useContext } from 'react';
import { Configuration } from '../../generated/graphql';
import { Image } from '../Image';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../../Context/DataContext';
import styles from './styles.module.scss';
import { Button } from '../Button';
import { Types } from '../Types';

interface indexProps {
  config: Configuration
  className: string
}

export const Config: React.FC<indexProps> = ({ config, className }) => {
  const { setConfig, collections } = useContext(DataContext);
  const history = useHistory();

  const handleColImage = col => {
    const collection = collections && collections.find(c => c.name === col);
    return collection && collection.image ? collection.image : null;
  }

  const handleClick = () => {
    setConfig(config)
    history.push("/selection/result")
  }

  return (
    <div className={styles.item}>
      <div className={styles.baseItem}>
        <div className={styles.baseInner} style={{ backgroundImage: `url(${handleColImage(config.collection)})` }}>
          <Image uri={handleColImage(config.collection)} name={config.name} hidden={true} />
          <div className={styles.baseTypes}>
            <Types bases={config.bases} radius={5} gap={0.05} />
          </div>
        </div>

        <span className={styles.baseName}>{config.name}</span>
        <div className={styles.buttonContainer}>
          <Button onClick={handleClick} label={"Select"} selfAlign={false} />
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Configuration, Base } from '../../../generated/graphql';
import { ContentElement } from '../../../Components/ContentElement';

import {
  FilePdfOutlined
} from '@ant-design/icons';
import styles from './technical.module.scss';

interface ModalProps {
  config: Base | Configuration
}

export const ModalContent: React.FC<ModalProps> = ({ config }) => {

  let videoPath: string;
  if (config.video) {
    if (config.video.includes('../web/public')) {
      videoPath = config.video.replace('../web/public', '');
    } else if (config.video.includes('../vitaris.visual-pool.de')) {
      videoPath = config.video.replace('../vitaris.visual-pool.de', '');
    } else {
      videoPath = config.video;
    }
  }

  return (
    <div className={styles.modalContainer}>
      <div className={styles.contentContainer}>
        {config.contents.sort((a: any, b: any) => a.sort - b.sort).map(c => (
          <ContentElement key={c.id} element={c} />
        ))}
      </div>
      <div className={styles.documentContainer}>
        {config && config.video && (
          <>
            <h3>Watch Video</h3>
            <video src={videoPath} playsInline={true} controls className={styles.video} poster={"/video_poster.png"} />
          </>
        )}
        {config && config.documents && (
          <>
            <h3>Documents</h3>
            <div className={styles.documentWrapper}>
              {config.documents.map((doc, i) => (
                <a key={i} href={doc} title={doc} target={"_blank"} className={styles.download}>
                  <FilePdfOutlined style={{ fontSize: '32px', color: '#ff0000' }} translate={'Download PDF'} />
                  {doc.replace("/files/documents/", "")}
                </a>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
import React, { useContext } from 'react'
import { useRevokeTokenMutation } from '../../../generated/graphql';
import { notification } from 'antd';
import Button from 'antd/es/button';
import {
  LogoutOutlined,
} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import { DataContext } from '../../../Context/DataContext';

type User = {
  id: number
  name: string
  email: string
  verified: boolean
  role: string
}

interface SessionProps {
  user: User
  refetch: Function
}

export const Session: React.FC<SessionProps> = ({ user, refetch }) => {
  const { me } = useContext(DataContext);
  const [revoke] = useRevokeTokenMutation();

  const handleRevoke = async () => {
    const revoked = await revoke({
      variables: {
        userId: user.id
      }
    });

    if (revoked.data) {
      notification.success({
        message: `User ${user.name} successfully logged out!`
      })
      refetch();
    } else {
      notification.error({
        message: 'Could not logout user!'
      })
    }
  }

  return (
    <Tooltip title={me.id === user.id ? "You can not revoke your own session" : "Revoke user session"}>
      <Popconfirm
        title="You really want to logout the user?"
        onConfirm={handleRevoke}
        placement="left"
        okText="Revoke"
        disabled={me.id === user.id}
      >
        <Button
          shape="circle"
          type="primary"
          disabled={me.id === user.id}
          icon={<LogoutOutlined translate={'revoke user session'} />}
        />
      </Popconfirm>
    </Tooltip>
  );
}
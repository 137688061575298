import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Footer from "../../Components/Footer";
import styles from "./styles.module.scss";

import Register from "../../Components/Register";
import Login from "../../Components/Login";
import Modal from "../../Components/Modal";
import { getAccessToken } from "../../accessToken";
import { Button } from "../../Components/Button";
import { Actions } from "../../Components/Actions";

const { useState } = React;

const Preseletion: React.FC<RouteComponentProps> = ({ history }) => {
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const token = getAccessToken();

  const handleOpenRegister = () => {
    setOpenRegister(!openRegister);
  };
  const handleOpenLogin = () => {
    setOpenLogin(!openLogin);
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Actions />
        <img className={styles.bgImg} src="/vitaris_bg.jpg" alt="background" />
        <div className={styles.headlineContainer}>
          <div className={styles.text}>
            <p>Thanks for joining the Vitaris-Configurator!</p>
            <strong>
              In a few steps we help you to configure
              <br />
              the best fitting solution for your needs.
            </strong>
            <p>
              You can always alter your decision by stepping back or in your
              configuration afterwards.
              <br />
              Also you can save and send all your configurations to your e-mail
              <br />
              for your further planing, inquiries or offer requests.
            </p>
          </div>
        </div>
      </div>
      <Footer refresh={() => history.push("/")}>
        <div className={!token ? styles.buttons : [styles.buttons, styles.activeButtons].join(' ')}>
          {!token && (
            <>
              <div className={styles.btnContainer}>
                <Button label={"Login to myVitaris"} onClick={handleOpenLogin} selfAlign={false} />
              </div>
              <div className={styles.btnContainer}>
                <Button label={"Register new User"} onClick={handleOpenRegister} selfAlign={false} />
              </div>
            </>
          )}
          {!!token && (<div />)}
          <div className={styles.btnContainer}>
            <Button path={`/selection`} label={!!token ? "Configure" : "Let ́s figure it out first!"} selfAlign={false} />
          </div>
          {!!token && (<div />)}
        </div>
      </Footer>
      <Modal
        open={openRegister}
        title={"Register User"}
        closeAction={handleOpenRegister}
        saveAction={() => { }}
      >
        <Register />
      </Modal>
      <Modal
        open={openLogin}
        title={"Login"}
        closeAction={handleOpenLogin}
        saveAction={() => { }}
      >
        <Login close={() => setOpenLogin(false)} />
      </Modal>
    </div>
  );
};

export default withRouter(Preseletion);

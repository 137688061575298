import React from 'react'
import Button from 'antd/es/button';
import {
  PicCenterOutlined,
  PicLeftOutlined,
  DeleteOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { useSpring, animated } from 'react-spring';

import styles from '../Fab/styles.module.scss';
import Tooltip from 'antd/es/tooltip';
// import { ContentType } from './Content';


interface fabProps {
  action: Function,
  align: string,
  open: boolean,
  setOpen: Function,
  remove: Function
}

export const EditFab: React.FC<fabProps> = ({ action, align, open, setOpen, remove }) => {
  const aniOne = useSpring({
    top: open ? -31 : 0,
    left: open ? -21 : 0,
    opacity: open ? 1 : 0
  })
  const aniTwo = useSpring({
    left: open ? -40 : 0,
    opacity: open ? 1 : 0
  })
  const aniThree = useSpring({
    top: open ? 31 : 0,
    left: open ? -21 : 0,
    opacity: open ? 1 : 0
  })

  return (
    <>
      <Button shape={"circle"} className={styles.icon} type="ghost" onClick={() => setOpen(o => !o)}>
        <MenuOutlined translate={'open edit'} />
      </Button>
      <div className={styles.typeHolder}>
        <animated.div className={styles.addContentButton} style={aniOne}>
          <Tooltip title="Align left">
            <Button shape={"circle"} type="primary" disabled={align === 'left'} onClick={() => action('left')}>
              <PicLeftOutlined translate={'align left'} />
            </Button>
          </Tooltip>
        </animated.div>
        <animated.div className={styles.addContentButton} style={aniTwo}>
          <Tooltip title="Align center">
            <Button shape={"circle"} type="primary" disabled={align === 'full'} onClick={() => action('full')}>
              <PicCenterOutlined translate={'align full'} />
            </Button>
          </Tooltip>
        </animated.div>
        <animated.div className={styles.addContentButton} style={aniThree}>
          <Tooltip title="Remove Item">
            <Button shape={"circle"} type="primary" disabled={align === 'right'} onClick={() => remove()}>
              <DeleteOutlined translate={'remove'} />
            </Button>
          </Tooltip>
        </animated.div>
      </div>
    </>
  );
}
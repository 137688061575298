import React from 'react';

import styles from './types.module.scss';

interface typeProps {
  type: string
  active: boolean
  radius: number
}

export const Type: React.FC<typeProps> = ({ type, active, radius }) => {
  let icon = "";
  let color = "#00b0a9";
  let borderRadius = "";
  switch (type) {
    case "Type 1":
      icon = "/Icon_01.png";
      color = active ? "#00b0a9" : "#c1c1c1";
      borderRadius = `${radius}px 0 0 0`;
      break;
    case "Type 2":
      icon = "/Icon_02.png";
      color = active ? "#5fb564" : "#c1c1c1";
      borderRadius = `0 ${radius}px 0 0`;
      break;
    case "Type 3":
      icon = "/Icon_03.png";
      color = active ? "#a1c860" : "#c1c1c1";
      borderRadius = `0 0 0 ${radius}px`;
      break;
    case "Type 4":
      icon = "/Icon_04.png";
      color = active ? "#dddf5b" : "#c1c1c1";
      break;
    default:
      icon = "/Icon_01.png";
      color = "#c1c1c1";
      break;
  }

  return (
    <div className={styles.type} style={{ backgroundColor: color, borderRadius: borderRadius }}>
      <img src={icon} alt={type} />
    </div>
  );
}
import React, { useState } from 'react'
import { Configuration, Base } from '../../../generated/graphql';
import Modal from 'antd/es/modal';
import { ModalContent } from './ModalContent';
import styles from './technical.module.scss';
import { Button } from '../../../Components/Button';


interface TechnicalProps {
  config: Base | Configuration
}

export const Technical: React.FC<TechnicalProps> = ({ config }) => {
  const [open, setOpen] = useState(false);
  const actualWidth = document.body.clientWidth;

  return (
    <div className={styles.technical}>
      <h3 className={styles.headline}>Detailed Informations</h3>
      <p>Click on the button below to see detailed technical specifications for your current combination.</p>
      <div className={styles.buttonWrapper}>
        <Button onClick={() => setOpen(o => !o)} label={"Technical Infos"} selfAlign={false} />
      </div>
      <Modal
        width={actualWidth < 1200 ? (actualWidth - 32) : 1200}
        title={null}
        visible={open}
        centered
        destroyOnClose={true}
        maskClosable={true}
        maskStyle={{ backgroundColor: 'rgba(0, 41, 38, 0.7)' }}
        closable={true}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <ModalContent config={config} />
      </Modal>
    </div>
  );
}
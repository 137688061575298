import React, { useContext, Suspense, useEffect, useState } from "react";
import { DataContext } from "../../Context/DataContext";
import ThreeD from "../../Components/ThreeD";
import styles from "./styles.module.scss";
import footerStyles from "./Footer/footer.module.scss";
import Loader from "../../Components/Loader";
import Footer from "./Footer/Footer";
import { useBasesQuery, Base, useConfigurationsQuery, Configuration, useSavedConfigMutation } from "../../generated/graphql";
import { Technical } from "./Technical/Technical";
import { Settings } from "./Settings/Settings";
import { Slider } from "./Slider/Slider";
import useDimensions from "../../Hooks/useDimensions";
import { Types } from "../../Components/Types";
import { Actions } from "./Actions";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../Components/Button";


const Result: React.FC = () => {
  // @ts-ignore
  let { id } = useParams();
  const history = useHistory();
  const [bases, setBases] = useState<Base[]>();
  const [activeBase, setActiveBase] = useState<Base>();
  const [showSlider, setShowSlider] = useState(true);
  const [error, setError] = useState(false);
  const [configuration, setConfiguration] = useState<Configuration>();
  const { config, setConfig } = useContext(DataContext);
  const { width } = useDimensions(window);
  const { data: basesData } = useBasesQuery();
  const { data: configData } = useConfigurationsQuery();
  const [loadConfig] = useSavedConfigMutation();

  const getConfig = async () => {
    console.log("getConfig")
    try {
      const conf = await loadConfig({
        variables: {
          uri: id
        }
      })
      if (conf.data.config) {
        // @ts-ignore
        const found: Configuration = configData.configurations.find(c => c.id === conf.data.config.id);

        setConfiguration(found);
        setConfig(found);
      }
    } catch (err) {
      setError(true);
    };
  }

  useEffect(() => {
    if (configData && configData.configurations) {
      // @ts-ignore
      let found: Configuration;
      if (id) {
        getConfig()
      } else {
        // @ts-ignore
        found = configData.configurations.find(c => c.id === config.id);
        if (found) {
          setConfiguration(found);
          setConfig(found);
        }
      }
    }
  }, [configData, id]) // eslint-disable-line

  useEffect(() => {
    if (bases && showSlider) {
      if (bases.length === 1) {
        setShowSlider(false);
      }
    }
  }, [bases]) // eslint-disable-line

  useEffect(() => {
    if (basesData && configuration && configuration.bases) {
      let basesArray = [];
      configuration.bases.forEach(({ id }) => {
        const foundBase = basesData.bases.find(b => b.id === id);
        if (foundBase) {
          basesArray = [
            ...basesArray,
            foundBase
          ];
        }
      });
      setBases(basesArray);
    }
  }, [basesData, configuration]); // eslint-disable-line

  const handleBack = () => {
    history.push("/selection");
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        {!error ?
          (
            <div className={styles.viewContainer}>
              <Suspense fallback={<Loader />}>
                {config && (
                  <>
                    <div className={styles.resultHeader}>
                      <Actions />
                      <div className={styles.subHeader}>Your selected Vitaris solution:</div>
                      <header className={styles.header}>{config.name}</header>
                    </div>
                    <ThreeD path={activeBase ? activeBase.collection : config.collection} />
                  </>
                )}
              </Suspense>
              <div className={styles.typesWrapper}>
                <Types bases={activeBase ? [activeBase] : bases} radius={width <= 767 ? 5 : 10} gap={.1} />
              </div>
              <div className={styles.threeSixty}>
                <img src={"/360_Grad_Icon.png"} alt={"360 Grad"} />
                {width > 500 && <span>Hold left click and drag sideways or touch and swipe sideways to rotate view.</span>}
              </div>
            </div>
          ) : (
            <div className={styles.viewContainer}>
              <div className={styles.errorContainer}>
                We could not find your selected Configuration.<br />
                Please try another link or configure a new Solution.
                <Button selfAlign={false} label={"Back to Selection"} onClick={handleBack} />
              </div>
            </div>
          )}
      </div>
      <div className={footerStyles.footer}>
        <div className={styles.bases}>
          {bases && (
            <Slider activeBase={activeBase} setActiveBase={setActiveBase} bases={bases} showSlider={showSlider} />
          )}
          {(config || activeBase) && <Technical config={activeBase ? activeBase : config} />}
          {(config || activeBase) && <Settings properties={activeBase ? activeBase.properties : config.properties} />}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Result;

import React, { useContext } from 'react';
import { useUpdateVerifyMutation } from '../../../generated/graphql';
import { DataContext } from '../../../Context/DataContext';
import { Checkbox, notification } from 'antd';
import Tooltip from 'antd/es/tooltip';

type User = {
  id: number
  name: string
  email: string
  verified: boolean
  role: string
}

interface RoleProps {
  user: User
  refetch: Function
}

export const Verified: React.FC<RoleProps> = ({ user, refetch }) => {
  const { me } = useContext(DataContext);
  const [updateVerify] = useUpdateVerifyMutation();

  const handleVerificationChange = async ({ target }) => {
    const updated = await updateVerify({
      variables: {
        id: user.id,
        verified: target.checked
      }
    });

    if (updated.data.updateVerify) {
      notification.success({
        message: `User ${user.name} successfully updated!`
      })
      refetch();
    } else {
      notification.error({
        message: 'Could not verify User!'
      })
    }
  }

  return (
    <Tooltip title={me.id === user.id ? "You can not verify yourself" : "Verify User manually"}>
      <strong>Verified: <Checkbox onChange={handleVerificationChange} disabled={me.id === user.id} checked={user.verified} /></strong>
    </Tooltip>
  );
}
import React from 'react'
import { useThumbnailQuery } from '../../../generated/graphql';
import { Image } from '../../../Components/Image';
import Popconfirm from 'antd/es/popconfirm';
import {
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import styles from './styles.module.scss';

interface DocumentProps {
  file: string
  remove: Function
}

export const Document: React.FC<DocumentProps> = ({ file, remove }) => {
  const { data } = useThumbnailQuery({ variables: { file } })

  const clearFileName = (file: string) => {
    if (file.includes('../web/public')) {
      return file.replace('../web/public', '');
    } else if (file.includes('../vitaris.visual-pool.de')) {
      return file.replace('../vitaris.visual-pool.de', '');
    } else {
      return file;
    }
  }

  return (data && data.thumbnail ? (
    <div className={styles.item}>
      <div className={styles.file}>
        <Image uri={data.thumbnail} name={data.thumbnail} />
        <Popconfirm
          placement="left"
          title={"Are you sure?"}
          onConfirm={() => remove(clearFileName(file))}
          icon={<ExclamationCircleOutlined translate={"confirm"} style={{ color: 'red' }} />}
          okText="Yes"
          cancelText="No"
        >
          <span className={styles.remove}>
            <DeleteOutlined translate="delete" />
          </span>
        </Popconfirm>
      </div>
      <span className={styles.label}>{clearFileName(file)}</span>
    </div>
  ) : (
      <div>
        <span className={styles.label}>{clearFileName(file)}</span>
      </div>
    ));
}
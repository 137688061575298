import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Actions } from "../../Components/Actions";
import { Button } from "../../Components/Button";
import Footer from "../../Components/Footer";
import styles from "./styles.module.scss";

const Home: React.FC<RouteComponentProps> = ({ history }) => {
  if (localStorage.getItem("selected")) {
    localStorage.removeItem("selected");
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Actions />
        <img className={styles.bgImg} src="intro.jpg" alt="background" />
        <div className={styles.headlineContainer}>

          <div className={styles.headline}>
            The modulare<br /><strong>Vitaris<br />Cleaningsystem.</strong>
          </div>
          <div className={styles.logoContainer}>
            <div className={[styles.logo, styles.blue].join(" ")}>
              <img src="1.png" alt="logo" />
            </div>
            <div className={[styles.logo, styles.green].join(" ")}>
              <img src="2.png" alt="logo" />
            </div>
            <div className={[styles.logo, styles.lightgreen].join(" ")}>
              <img src="3.png" alt="logo" />
            </div>
            <div className={[styles.logo, styles.yellow].join(" ")}>
              <img src="4.png" alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <Footer refresh={() => history.push("/")} >
        <Button path={`/preselection`} label={"Start"} />
      </Footer>
    </div>
  );
};

export default Home;
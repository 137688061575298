import React, { useEffect, useState } from 'react'
import { Base, Collection } from '../../../generated/graphql';
import { Image } from '../../../Components/Image';
import styles from './base.module.scss';
import useDimensions from '../../../Hooks/useDimensions';
import { Types } from '../../../Components/Types';
import { CloseOutlined } from '@ant-design/icons';

interface BaseProps {
  base: Base
  activeBase: Base
  collections: Collection[]
  handleClick: Function
  basesCount: number
  curIndex: number
  inSlider?: boolean
}

export const BaseComponent: React.FC<BaseProps> = ({ base, activeBase, collections, handleClick, basesCount, curIndex, inSlider = true }) => {
  const collection = collections.find(c => c.name === base.collection);
  const [baseClasses, setBaseClasses] = useState<string>(styles.item);
  const { width } = useDimensions(window);

  const selectBase = () => {
    if (activeBase === base) {
      handleClick(null)
    } else {
      handleClick(base);
    }
  }

  useEffect(() => {
    if (activeBase === null || activeBase === undefined) {
      setBaseClasses(styles.item);
    } else {
      if (activeBase === base) {
        setBaseClasses([styles.item, styles.active].join(" "));
      } else {
        setBaseClasses([styles.item, styles.inactive].join(" "));
      }
    }
  }, [activeBase]); // eslint-disable-line

  let uriPath: string;
  if (collection && collection.image) {
    if (collection.image.includes('../web/public')) {
      uriPath = collection.image.replace('../web/public', '');
    } else if (collection.image.includes('../vitaris.visual-pool.de')) {
      uriPath = collection.image.replace('../vitaris.visual-pool.de', '');
    } else {
      uriPath = collection.image;
    }
  }

  return (uriPath ? (
    <>
      <div className={baseClasses} onClick={selectBase}>
        <div className={styles.baseItem}>
          <div className={styles.baseInner} style={{ backgroundImage: `url(${uriPath})` }}>
            <Image uri={collection.image} name={base.name} hidden={true} />
          </div>

          <div className={styles.baseTypes}>
            <Types bases={[base]} radius={width <= 767 ? 3 : 5} gap={0.05} />
          </div>
        </div>
        <div className={styles.close}><CloseOutlined translate={'close'} /></div>
      </div>
      <span className={styles.baseName}>{base.name}</span>
      {width >= 768 && curIndex !== (basesCount - 1) && (
        <div className={inSlider ? styles.separator : [styles.separator, styles.noSlideSeparator].join(" ")}>+</div>
      )}
    </>
  ) : null);
}
import React from 'react';
import { BaseButton } from "./Buttons/BaseButton";
import { MyList } from "../../Components/MyList";

import { ReactComponent as ListIcon } from './Buttons/myList.svg';
import { ReactComponent as SaveIcon } from './Buttons/save.svg';
// import { ReactComponent as SendIcon } from './Buttons/send.svg';
import { ReactComponent as UserIcon } from './Buttons/user.svg';
import { ReactComponent as ChangeIcon } from './Buttons/change.svg';

import styles from "./styles.module.scss";
import { Redirect } from 'react-router-dom';
import { Save } from '../../Components/Save';
import { UserSettings } from '../../Components/User';

export const Actions: React.FC = () => {
  return (
    <div className={styles.actionContainer}>
      <BaseButton
        label={"my List"}
        title={"My List"}
        icon={
          <ListIcon fill={'#fff'} color={'#fff'} className={styles.icon} />
        }
      >
        <MyList />
      </BaseButton>
      <BaseButton
        label={"Save"}
        title={"Save your current Configuration"}
        icon={
          <SaveIcon fill={'#fff'} color={'#fff'} className={styles.icon} />
        }
      >
        <Save />
      </BaseButton>
      {/* <BaseButton
        label={"Send"}
        title={"Send your current Configuration"}
        icon={
          <SendIcon fill={'#fff'} color={'#fff'} className={styles.icon} />
        }
      /> */}
      <BaseButton
        label={"User"}
        title={"Edit your account details"}
        icon={
          <UserIcon fill={'#fff'} color={'#fff'} className={styles.icon} />
        }
      >
        <UserSettings />
      </BaseButton>
      <BaseButton
        label={"Back"}
        title={""}
        checkLogin={false}
        icon={
          <ChangeIcon fill={'#fff'} color={'#fff'} className={styles.icon} />
        }
      >
        <Redirect to={{ pathname: '/selection' }} />
      </BaseButton>
    </div>
  );
}
import React, { Suspense, CSSProperties } from 'react';
import { useImage } from 'react-image';
import Loader from '../Loader';

interface imageProps {
  uri: string
  name: string
  selected?: boolean
  onClick?: any
  hidden?: boolean
  classes?: string | null
}

interface iProps {
  path: string
  style: CSSProperties
  alt: string
  onClick: Function
  classes?: string | null
}

const ImageComponent = ({ path, style, alt, onClick, classes }: iProps) => {
  let uriPath: string;
  if (path.includes('../web/public')) {
    uriPath = path.replace('../web/public', '');
  } else if (path.includes('../vitaris.visual-pool.de')) {
    uriPath = path.replace('../vitaris.visual-pool.de', '');
  } else {
    uriPath = path;
  }
  const { src, error } = useImage({
    srcList: uriPath,
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      {error && console.log(error)}
      {!error ? <img src={src} alt={alt} onClick={handleClick} style={style} className={classes} /> : null}
    </>
  )
}

export const Image: React.FC<imageProps> = ({ uri, name, selected, onClick, hidden = false, classes = "" }) => {
  const selectedStyle: CSSProperties = selected ? {
    border: "3px solid #1890ff",
    visibility: hidden ? 'hidden' : 'visible'
  } : {
      border: "3px solid #fff",
      visibility: hidden ? 'hidden' : 'visible'
    }

  return uri && name && (
    <Suspense fallback={<Loader />}>
      <ImageComponent style={selectedStyle} path={uri} alt={name} onClick={onClick} classes={classes} />
    </Suspense>
  );
}
import React, { useState, Suspense } from 'react'
import Card from 'antd/es/card';
import Progress from 'antd/es/progress';
import Tabs from 'antd/es/tabs';
import {
  PlusOutlined,
  MinusOutlined,
  AppstoreOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import styles from './styles.module.scss';
import {
  useCollectionsQuery
} from '../../../generated/graphql';
import Loader from '../../../Components/Loader';
import { Upload } from './Upload';
import { CollectionComponent } from './Collections';
import Button from 'antd/es/button';
import { Resolver } from './Resolver';

const { TabPane } = Tabs;

export const Files: React.FC = () => {
  const [openUpload, setOpenUpload] = useState(false);
  const [active, setActive] = useState<string>('1');
  const { data, refetch } = useCollectionsQuery();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  return (
    <Card
      title={"Files"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      className={styles.content}
      bordered={false}
      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => setOpenUpload(c => !c)}>
          {!openUpload ? <PlusOutlined translate={'open create'} /> : <MinusOutlined translate={'close create'} />}
        </Button>
      }
    >
      <div className="files">

        {openUpload && <Upload active={active} setUploading={setIsUploading} setProgress={setProgress} refetch={refetch} />}

        <div className={""}>
          {isUploading && <Progress percent={progress} status="active" showInfo={false} />}

          <Tabs defaultActiveKey="1" size={'large'} onChange={a => setActive(a)}>
            <TabPane tab={
              <span>
                <AppstoreOutlined translate={'Collection'} />
                  Collection
                </span>
            } key="1">
              <div className={styles.grid}>
                <Suspense fallback={<Loader />}>
                  {data && data.collections.map((col, i) => (
                    <CollectionComponent key={i} collection={col} refetch={refetch} />
                  ))}
                </Suspense>
              </div>
            </TabPane>
            <TabPane tab={
              <span>
                <FileImageOutlined translate={'Images'} />
                  Images
                </span>
            } key="2">
                <Resolver type={'images'} />
            </TabPane>

            <TabPane tab={
              <span>
                <VideoCameraOutlined translate={'Videos'} />
                  Videos
                </span>
            } key="3">
                <Resolver type={'videos'} />
            </TabPane>

            <TabPane tab={
              <span>
                <FilePdfOutlined translate={'Documents'} />
                  Documents
                </span>
            } key="4">
                <Resolver type={'documents'} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Card >
  );
}
import React from 'react'
import Icons from './sprite.svg';

interface IconProps {
  name: string
  color: string
  size: number
}

export const Icon: React.FC<IconProps> = ({ name, color, size }) => (
  <svg className={`icon icon-${name}`} fill={color} width={size} height={size}>
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);


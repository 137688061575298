import React from 'react'
import styles from './styles.module.scss';

interface dummyProps {
  open: Function
}

export const Dummy: React.FC<dummyProps> = ({ open }) => {


  return (
    <div className={styles.dummy}>
      <div className={styles.dummyContent} onClick={() => open()}>
        Select a File
      </div>
    </div>
  );
}
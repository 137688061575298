import React, { useState } from 'react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import CKEditor from '@ckeditor/ckeditor5-react';
import { ContentType } from '../../Admin/Components/Content';
import styles from './editor.module.scss';
import { EditFab } from './EditFab';
import config from './Config/config.json';
import headingConfig from './Config/heading.json';

type Option = {
  model?: string,
  view?: string,
  title?: string,
  class?: string
}

interface EditorProps {
  defaultConfig?: {
    toolbar?: {
      items?: string[]
    },
    image?: {
      toolbar?: string[]
    }
    heading?: {
      options?: Option[],
    },
    language?: string,
    placeholder?: string,
    readOnly?: boolean
  },
  contentObj: ContentType,
  index: number,
  set: Function
}

export const Editor: React.FC<EditorProps> = ({ defaultConfig, contentObj, index, set }) => {
  const [editorData] = useState(contentObj.content);
  const [open, setOpen] = useState(false);

  let editorConfig = defaultConfig;

  if (!editorConfig) {
    editorConfig = contentObj.type === "headline" ? headingConfig : config;
  }

  const handleContent = (data: string) => {
    setOpen(false)
    const content = {
      ...contentObj,
      content: data
    }

    set(c => {
      c[index] = content;
      return [...c]
    })
  }

  const handleAlign = (align: string) => {
    setOpen(false)
    const content = {
      ...contentObj,
      align
    }

    set(c => {
      c[index] = content;
      return [...c]
    })
  }

  const handleRemove = () => {
    setOpen(false)
    set(c => {
      return [...c.filter((it) => it.id !== contentObj.id)];
    })
  }

  return (
    <>
      <span className={styles.alignHandle}>
        <EditFab action={handleAlign} align={contentObj.align} open={open} setOpen={setOpen} remove={handleRemove} />
      </span>
      <CKEditor
        editor={BalloonEditor}
        data={editorData}
        config={editorConfig}
        onInit={(editor: EditorProps) => {
          // You can store the "editor" and use when it is needed.
          // console.log('Editor is ready to use!', editor);
        }}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          handleContent(data);
        }}
      />
    </>
  );
}
import React, { useState, useEffect, Suspense, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContext } from "../../Context/DataContext";
import styles from "./styles.module.scss";
import Footer from "../../Components/Footer";
import { Select } from "../../Components/Select";
import { useFilterOptionsQuery } from "../../generated/graphql";
import Loader from "../../Components/Loader";
import { Button } from "../../Components/Button";
import { Filter } from "../../Components/Filter";
import { Actions } from "../../Components/Actions";

type Type = {
  [key: string]: string
}

type Selector = {
  id: number
  label: string
  description: string
  sort: number
}

type OptionType = {
  id: number,
  name: string,
  sort: number,
  selector: Selector
}

type LabelWithOptions = {
  label: string,
  description: string,
  options: OptionType[]
}

interface props {
  selections: string
}

const Selection: React.FC<RouteComponentProps & props> = ({ history }) => {
  const selectedFromStorage = localStorage.getItem("selected") ? JSON.parse(localStorage.getItem("selected")) : null;
  const [next, setNext] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [types, setTypes] = useState<LabelWithOptions[]>();
  const [selected, setSelected] = useState<Type>(selectedFromStorage)
  const { data: options } = useFilterOptionsQuery();
  const { setSelected: set } = useContext(DataContext);

  useEffect(() => {
    if (options && options.filterOptions) {
      const op = options.filterOptions.reduce<LabelWithOptions[]>((acc: any, d: any) => {
        const type = d.selector.label;
        const desc = d.selector.description;
        if (!acc[type]) {
          acc[type] = [];
          acc[type]["label"] = type;
          acc[type]["description"] = desc;
          acc[type]["options"] = []
        }
        acc[type]["options"].push(d as OptionType[])
        return acc;
      }, [] as LabelWithOptions[]);

      setTypes(op);
    }
  }, [options])

  const resetSelected = () => {
    localStorage.removeItem("selected");
    setSelected({});
    setNext(false);
    setIsFiltered(false);
  }

  const setValue = (option: string, label: string) => {
    setSelected({
      ...selected,
      [label]: option
    })
  }

  useEffect(() => {
    if (types && selected && Object.entries(selected).length === Object.entries(types).length) {
      set(selected);
      setNext(true);
    }
  }, [selected, types]) // eslint-disable-line

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Actions />
        <img className={styles.bgImg} src="vitaris_bg.jpg" alt="background" />
        <div className={styles.headlineContainer}>
          <div className={styles.text}>
            <p>
              Allright, first things first.
            </p>
            <p>
              Choose your scenario:
            </p>
          </div>
          <div className={styles.questions}>
            <Suspense fallback={<Loader />}>
              {types && Object.entries(types).map(([index, { label, options, description }]) => (
                <Select key={index} description={description} options={options} setValue={setValue} label={label} selected={selected ? selected[label] : undefined} />
              ))}
            </Suspense>
          </div>
          <Filter isFiltered={isFiltered} />
        </div>
      </div>
      <Footer refresh={() => history.push("/")}>
        {next && !isFiltered && (
          <Button onClick={() => setIsFiltered(true)} label={"Filter"} />
        )}
        {isFiltered && (
          <Button onClick={resetSelected} label={"Reset"} />
        )}
      </Footer>
    </div>
  );
};

export default Selection;

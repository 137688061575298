import React, { useState, useRef, Suspense } from 'react'
import { useSpring, animated } from 'react-spring'
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import Divider from 'antd/es/divider';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Table from 'antd/es/table';
import Space from 'antd/es/space';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Alert from 'antd/es/alert';
import { notification } from 'antd';
import Highlighter from 'react-highlight-words';
import {
  PlusOutlined,
  MinusOutlined,
  CheckOutlined,
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styles from './styles.module.scss';
import { Selection, useCreateOptionMutation, useUpdateOptionMutation } from '../../../generated/graphql';
import Loader from '../../../Components/Loader';
import Popconfirm from 'antd/es/popconfirm';

const { Paragraph } = Typography;
const { Option } = Select;

type DropDownType = {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

type OptionEntity = {
  id: number,
  name: string,
  selectorId: number,
  selector?: Selection,
  sort: number
}

type optionsProps = {
  selectionsData: any,
  optionsData: any,
  refetchOptions: Function,
  removeOption: Function
}

export const Options: React.FC<optionsProps> = ({ selectionsData, optionsData, refetchOptions, removeOption }) => {
  const [createOption] = useCreateOptionMutation();
  const searchRef = useRef<Input>(null);
  const [query, setQuery] = useState("");
  const [column, setColumn] = useState("");
  const [updateOption, { loading }] = useUpdateOptionMutation();
  const [openCreate, setOpenCreate] = useState(false);
  const [selectorId, setSelector] = useState<number | undefined>(undefined);
  const [name, setName] = useState("");
  const [sort, setSort] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const ani = useSpring({
    maxHeight: openCreate ? 100 : 0
  });

  const handleSelectorChange = (val: any) => {
    if (typeof val !== "undefined") {
      setSelector(parseInt(val));
    }
  }

  const handleLabelChange = (e: any) => {
    setName(e.target.value)
  }

  const handleSortChange = (e: any) => {
    setSort(parseInt(e.target.value))
  }

  const onFinish = async (e: any) => {
    e.preventDefault();

    if (testType({ name, selectorId, sort })) {
      const created = await createOption({
        variables: {
          name,
          sort,
          selectorId
        }
      });
      if (created.data.createOption) {
        notification.success({
          message: `Option successfully created!`
        });
        setSelector(undefined)
        setName("")
        setSort(0)
        refetchOptions();
      } else {
        notification.error({
          message: `Could not create Option!`
        });
      }
    } else {
      setError("Not all fields are filled correctly");
    }
  }

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setQuery(selectedKeys[0]);
    setColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setQuery("")
    setColumn("")
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: DropDownType) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined translate={"search"} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined translate={"search"} style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) => {
      if (dataIndex.includes(".")) {
        return record["selector"]["label"].toString().toLowerCase().includes(value.toLowerCase())
      } else {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      }

    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchRef!.current!.select());
      }
    },
    render: (text: string) =>
      column === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[query]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  const handleUpdate = (type: string, value: string | number, record: OptionEntity) => {
    updateOption({
      variables: {
        ...record,
        selectorId: record!.selector!.id,
        [type]: value
      },
      update: () => {
        refetchOptions();
      }
    });
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      ...getColumnSearchProps('name'),
      render: (text: any, record: any) => <Paragraph style={{ marginBottom: 0 }} editable={{ onChange: val => handleUpdate("name", val, record) }}>{text}</Paragraph>
    },
    {
      title: 'Selector',
      dataIndex: 'selector',
      key: 'selector',
      sorter: (a: any, b: any) => a.selector.id - b.selector.id,
      ...getColumnSearchProps('selector.label'),
      render: (text: any, record: any) => (
        <>
          <Select
            showSearch
            style={{ width: "100%" }}
            size="large"
            value={record.selector.id}
            placeholder="Selector"
            onChange={val => handleUpdate("selectorId", val, record)}
            optionFilterProp={"children"}
            filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {selectionsData && selectionsData.selections.map((selection, idx) => {
              return <Option key={idx} value={selection.id}>{selection.label}</Option>
            })}
          </Select>
        </>
      )
    },
    {
      title: 'Sorting',
      dataIndex: 'sort',
      key: 'sort',
      width: 100,
      sorter: (a: any, b: any) => a.sort - b.sort,
      render: (text: any, record: any) => <Paragraph style={{ marginBottom: 0, textAlign: "center" }} editable={{ onChange: val => handleUpdate("sort", parseInt(val), record) }}>{text.toString()}</Paragraph>
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      width: 100,
      render: (item: OptionEntity) => (
        <Popconfirm
          placement="left"
          title={"Are you sure?"}
          onConfirm={() => removeOption(item.id)}
          icon={<ExclamationCircleOutlined translate={"confirm"} style={{ color: 'red' }} />}
          okText="Yes"
          cancelText="No"
        >
          <span>
            <DeleteOutlined translate={"delete"} style={{ cursor: 'pointer', color: '#1890ff' }} />
          </span>
        </Popconfirm>
      )
    },
  ];

  const testType = (t: any) => {
    return t.name !== '' && t.selectorId !== null && !isNaN(t.selectorId) && !isNaN(t.sort);
  }

  return (
    <Card
      title={"Options"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      className={styles.content}
      bordered={false}
      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => setOpenCreate(c => !c)}>
          {!openCreate ? <PlusOutlined translate={'open create'} /> : <MinusOutlined translate={'close create'} />}
        </Button>
      }
    >
      <Suspense fallback={<Loader />}>
        <animated.div style={ani} className={styles.createContainer}>
          <Row gutter={16}>
            <Col flex="200px">
              <Input size={"large"} placeholder={"Option Label"} allowClear onChange={handleLabelChange} value={name} required />
            </Col>
            <Col flex="260px">
              <Row gutter={16}>
                <Col flex="175px">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    size="large"
                    allowClear
                    value={selectorId}
                    placeholder="Selector"
                    onChange={handleSelectorChange}
                    optionFilterProp={"children"}
                    filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {selectionsData && selectionsData.selections.map((selection, idx) => {
                      return <Option key={idx} value={selection.id}>{selection.label}</Option>
                    })}
                  </Select>
                </Col>
                <Col flex="75px">
                  <Input size={"large"} type={"number"} placeholder={"Sort"} onChange={handleSortChange} value={isNaN(sort) ? 0 : sort} min="0" onPressEnter={onFinish} />
                </Col>
              </Row>
            </Col>
            <Col flex="50px" className={styles.rightCol}>
              <Button shape={"circle"} className={styles.button} type="primary" onClick={onFinish}>
                <CheckOutlined translate={'close create'} />
              </Button>
            </Col>
          </Row>
          {error && <Alert message={error} type="error" showIcon closable afterClose={() => setError(null)} />}
          <Divider />
        </animated.div>
        <Table rowKey="id" loading={loading} size={"small"} tableLayout={"fixed"} dataSource={optionsData ? optionsData.options : []} columns={columns} />
      </Suspense>
    </Card>
  );
}

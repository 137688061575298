import React from 'react'
import styles from './styles.module.scss';
import { FileModal } from './FileModal';

interface FileProps {
  file?: string
  remove?: any
}

export const File: React.FC<FileProps> = ({ file, remove }) => {
  const clearFileName = (file) => {
    if (file.includes('../web/public')) {
      return file.replace('../web/public', '');
    } else if (file.includes('../vitaris.visual-pool.de')) {
      return file.replace('../vitaris.visual-pool.de', '');
    } else {
      return file;
    }
  }

  const handleRemove = (file) => {
    const fileName = file ? clearFileName(file) : null;
    remove(fileName);
  }

  return (
    <>
      {file && (
        <div className={styles.item}>
          <FileModal file={file} remove={handleRemove} />
          <span className={styles.label}>{clearFileName(file)}</span>
        </div>
      )}
      {!file && (<div className={styles.error}>No File provided!</div>)}
    </>
  );
}
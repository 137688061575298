import React, { useContext, useEffect, useState } from 'react'
import { BaseComponent } from '../Base/Base';
import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DataContext } from '../../../Context/DataContext';
import { Base } from '../../../generated/graphql';
import styles from "./slider.module.scss";
import useDimensions from '../../../Hooks/useDimensions';
import 'swiper/swiper.scss';
SwiperCore.use([Virtual]);

interface SliderProps {
  activeBase: Base
  setActiveBase: Function
  bases: Base[]
  showSlider: boolean
}

export const Slider: React.FC<SliderProps> = ({ activeBase, setActiveBase, bases, showSlider }) => {
  const { collections } = useContext(DataContext);
  const [swiper, setSwiper] = useState(null);
  const { width } = useDimensions(window);
  const [disabled, setDisabled] = useState({ next: false, prev: true });
  let slidesPerView = 1;

  if (width <= 361) {
    slidesPerView = 1;
  } else if (width <= 500) {
    slidesPerView = 2;
  } else if (width <= 1024) {
    slidesPerView = 2;
  } else if (width <= 1440) {
    slidesPerView = 2;
  } else if (width <= 1600) {
    slidesPerView = 3;
  } else {
    slidesPerView = 4;
  }

  const params = {
    breakpoints: {
      0: {
        slidesPerView: 1
      },
      450: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 2
      },
      1440: {
        slidesPerView: 3
      },
      1600: {
        slidesPerView: 4
      }
    },
    virtual: false,
    spaceBetween: 50,
    slidesPerView,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
  }

  const handleSelectBase = (base: Base) => {
    setActiveBase(base);
  }

  const handleSwipes = () => {
    if (swiper) {
      // console.log((bases.length <= slidesPerView || swiper.isEnd))
      setDisabled({ prev: swiper.isBeginning, next: (bases.length <= slidesPerView || swiper.isEnd) })
    }
  }

  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
      handleSwipes();
    }
  };

  const goPrev = () => {
    if (swiper && showSlider) {
      swiper.slidePrev();
      handleSwipes();
    }
  };

  useEffect(() => {
    if (showSlider && swiper && bases && bases.length > 0) {
      handleSwipes();
      swiper.on('slideChange', () => {
        handleSwipes();
      });
    }
  }, [bases, swiper]) // eslint-disable-line

  return (showSlider ? (
    <div className={styles.container}>
      <div>
        <Swiper onSwiper={(sw) => setSwiper(sw)} {...params} className={styles.swiperContainer}>
          {bases && bases.map((base, i) => {
            return (
              <SwiperSlide key={base.id} virtualIndex={i} className={styles.slideItem}>
                <BaseComponent key={base.id} base={base} collections={collections} handleClick={handleSelectBase} activeBase={activeBase} basesCount={bases.length} curIndex={i} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div onClick={goPrev} className={disabled.prev ? [styles.swipePrev, styles.swipeDisabled].join(' ') : styles.swipePrev} />
        <div onClick={goNext} className={disabled.next ? [styles.swipeNext, styles.swipeDisabled].join(' ') : styles.swipeNext} />
      </div>
    </div>
  ) : (
      <div className={[styles.container, styles.noSlider].join(" ")}>
        {bases.map((base, i) => (
          <div className={styles.item} key={base.id}>
            <BaseComponent inSlider={false} base={base} collections={collections} handleClick={handleSelectBase} activeBase={activeBase} basesCount={bases.length} curIndex={i} />
          </div>
        ))}
      </div>
    ));
}
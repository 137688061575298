import React, { Suspense } from 'react';
import { Button, Card } from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';
import styles from './introduction.module.scss';
import Loader from '../../../Components/Loader';
import { IntroCard } from './IntroCard';
import { useCreateStepMutation, useRemoveStepMutation, useStepsQuery } from '../../../generated/graphql';

export const Introduction: React.FC = () => {
  const { data, refetch } = useStepsQuery({fetchPolicy: 'no-cache'});
  const [create] = useCreateStepMutation();
  const [remove] = useRemoveStepMutation();

  const handleRemove = async (id: number) => {
    const removed = await remove({
      variables: {
        id
      }
    });

    if (removed.data.removeStep) {
      refetch();
    }
  }

  return (
    <Card
      title={"Introduction"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      className={styles.content}
      bordered={false}
    >
      <Suspense fallback={<Loader />}>
          <div className={styles.container}>
            {data && data.steps.map((step) => (
              <IntroCard key={step.id} step={step} remove={handleRemove} />
            ))}

            <div className={styles.addButton}>
              <Button
                type="dashed"
                onClick={async () => {
                  await create({
                    variables: {
                      title: '',
                      subtitle: '',
                      image: '',
                      text: '',
                      icon: ''
                    }
                  });
                  await refetch();
                }}
                block
              >
                <PlusOutlined translate={"no"} /> Add Step
              </Button>
            </div>
          </div>
      </Suspense>
    </Card>
  );
}
import React from 'react'
import {
  SortableHandle
} from 'react-sortable-hoc';
import Button from 'antd/es/button';
import {
  DragOutlined
} from '@ant-design/icons';
import styles from './sortable.module.scss';

export const DragHandle = SortableHandle(() => (
  <span className={["handle", styles.sortHandle].join(" ")}>
    <Button shape={"circle"} className={styles.icon} type="ghost">
      <DragOutlined translate={'Sort handle'} />
    </Button>
  </span>
));
import { Checkbox } from "antd";
import React from "react";
import styles from "./styles.module.scss";

interface inputProps {
  id?: string
  value: string,
  setValue: Function,
  placeholder: string,
  type: string,
  required?: boolean,
  pattern?: string,
  name?: string
}

const Input: React.FC<inputProps> = ({ id = "", value, setValue, placeholder, type, required = false, pattern = undefined, name }) => {
  return (type === 'checkbox' ? (
    <div className={styles.checkbox}>
      <Checkbox
        checked={value === 'false' ? false : true}
        name={name}
        onChange={({ target }) => setValue(target.checked)}
      >
        {placeholder}
      </Checkbox>
    </div>
  ) : (
      <input
        id={id}
        value={value}
        required={required}
        pattern={pattern}
        placeholder={placeholder}
        type={type}
        name={name}
        className={styles.input}
        onChange={({ target }) => setValue(target.value)}
      />
    )
  );
};

export default Input;

import React, { useState, Suspense } from 'react'
import Card from 'antd/es/card';
import styles from './styles.module.scss'
import { useRemoveConfigurationMutation, Configuration } from '../../../generated/graphql';
import {
  PlusOutlined,
  MinusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import Button from 'antd/es/button';
import { notification, Collapse } from 'antd';
import Loader from '../../../Components/Loader';
import { CreateConfig } from './CreateConfig';
import List from 'antd/es/list';
import Popconfirm from 'antd/es/popconfirm';

const { Panel } = Collapse;

interface ConfigProps {
  configurationData: any
  refetch: Function
}

export const ConfigurationComponent: React.FC<ConfigProps> = ({ configurationData, refetch }) => {
  const [removeConfig] = useRemoveConfigurationMutation();
  const [openCreate, setOpenCreate] = useState(false);

  const removeConfiguration = async id => {
    const removed = await removeConfig({
      variables: {
        id
      }
    });
    if (removed.data.removeConfiguration) {
      notification.success({
        message: `Configuration successfully removed!`
      });
      refetch();
    } else {
      notification.error({
        message: `Could not remove Configuration!`
      });
    }
  }

  const genExtra = (id: number) => (
    <div>
      <Popconfirm
        placement="left"
        title={"Are you sure you want to delete the Configuration and all related Content elements?"}
        onConfirm={e => {
          e.stopPropagation();
          removeConfiguration(id);
        }}
        onCancel={e => e.stopPropagation()}
        icon={<ExclamationCircleOutlined translate={"confirm"} style={{ color: 'red' }} />}
        okText="Yes"
        cancelText="No"
      >
        <DeleteOutlined
          translate={"remove"}
          style={{ fill: '#ff0000', color: '#ff0000' }}
          onClick={e => e.stopPropagation()}
        />
      </Popconfirm>
    </div>
  );

  return (
    <Card
      title={"Configurations"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      className={styles.content}
      bordered={false}
      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => setOpenCreate(c => !c)}>
          {!openCreate ? <PlusOutlined translate={'open create'} /> : <MinusOutlined translate={'close create'} />}
        </Button>
      }
    >
      <CreateConfig openCreate={openCreate} fetchConfig={refetch} />

      <Suspense fallback={<Loader />}>
        <List
          size="large"
          dataSource={configurationData ? configurationData.configurations : []}
          renderItem={(item: Configuration) => (
            <Collapse
              defaultActiveKey={['1']}
              expandIconPosition={'right'}
            >
              <Panel key={item.id} header={item.name} extra={genExtra(item.id)}>
                <CreateConfig openCreate={true} fetchConfig={refetch} config={item} />
              </Panel>
            </Collapse>
          )}
        />
      </Suspense>
    </Card >
  );
}
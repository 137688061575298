import React, { useState } from 'react'
import Modal from 'antd/lib/modal';
import { Image } from '../../../Components/Image';
import styles from './styles.module.scss';
import Popconfirm from 'antd/es/popconfirm';
import { Video } from '../../../Components/Video';
import {
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';

interface FileModalProps {
  file: string
  remove: Function
}

export const FileModal: React.FC<FileModalProps> = ({ file, remove }) => {
  const isImage = new RegExp("^.*.(png|jpg|jpeg|JPG|JPEG|PNG)$").test(file);
  const isVideo = new RegExp("^.*.(webm|avi|ogv|ogg|mp4)$").test(file);
  const isDoc = new RegExp("^.*.(pdf)$").test(file);
  const [open, set] = useState<boolean>(false);

  const clearFileName = (file) => {
    if (file.includes('../web/public')) {
      return file.replace('../web/public', '');
    } else if (file.includes('../vitaris.visual-pool.de')) {
      return file.replace('../vitaris.visual-pool.de', '');
    } else {
      return file;
    }
  }

  return (
    <>
      <div className={styles.file}>
        {isImage && <Image uri={file} name={clearFileName(file)} onClick={() => set(true)} />}
        {isVideo && <Video onClick={() => set(true)} uri={clearFileName(file)} name={clearFileName(file)} />}
        {isDoc && <div>{file}</div>}
        <Popconfirm
          placement="left"
          title={"Are you sure?"}
          onConfirm={() => remove(file)}
          icon={<ExclamationCircleOutlined translate={"confirm"} style={{ color: 'red' }} />}
          okText="Yes"
          cancelText="No"
        >
          <span className={styles.remove}>
            <DeleteOutlined translate="delete" />
          </span>
        </Popconfirm>
      </div>
      <Modal
        title={clearFileName(file)}
        centered
        visible={open}
        onOk={() => set(false)}
        onCancel={() => set(false)}
        width={1024}
        className={styles.modal}
        maskClosable={true}
        footer={null}
      >
          {isImage && <Image uri={file} name={clearFileName(file)} />}
          {isVideo && <Video uri={clearFileName(file)} name={clearFileName(file)} />}
          {isDoc && <div>{file}</div>}
      </Modal>
    </>
  );
}
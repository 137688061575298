import React, { Suspense } from 'react'
import { useFilesByTypeQuery, useRemoveFileMutation } from '../../../generated/graphql';
import Loader from '../../../Components/Loader';
import { notification } from 'antd';
import { File } from './File';
import { Document } from './Document';
import styles from './styles.module.scss';

interface ResolverProps {
  type: string
}

export const Resolver: React.FC<ResolverProps> = ({ type }) => {
  const [removeFile] = useRemoveFileMutation()
  const { data, refetch } = useFilesByTypeQuery({ variables: { type } });

  const handleRemove = async (file: string) => {
    if (file) {
      const removedFile = await removeFile({
        variables: {
          file
        }
      });
      if (removedFile.data.removeFile) {
        notification.success({
          message: `File successfully removed!`
        });
        refetch()
      } else {
        notification.error({
          message: `Could not remove File!`
        });
      }
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className={styles.grid}>
      {data && data.filesByType.map((file, i) => {
        if (type === 'documents') {
          return <Document key={i} file={file} remove={handleRemove} />;
        } else {
          return <File key={i} file={file} remove={handleRemove} />;
        }
      })}
      </div>
    </Suspense>
  );
}
import React from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { DataProvider } from './Context/DataContext'
import Home from "./Pages/Home/";
import { Admin } from "./Admin";
import Preselection from "./Pages/Preselection";
import Result from "./Pages/Result";
import Selection from "./Pages/Selection";
import Filter from "./Pages/Filter";
import Verify from "./Pages/Verify";

export const Routes: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <DataProvider location={location} history={history}>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/admin/:id" component={Admin} />
          <Route exact path="/preselection" component={Preselection} />
          <Route exact path="/selection" component={Selection} />
          <Route exact path="/selection/filter" component={Filter} />
          <Route exact path="/selection/result" component={Result} />
          <Route exact path="/selection/result/:id" component={Result} />
          <Route exact path="/user/verify/:token" component={Verify} />
        </Switch>
      </div>
    </DataProvider>
  );
};

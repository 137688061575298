import React, { useContext } from 'react'
import { useUpdateRoleMutation } from '../../../generated/graphql';
import { DataContext } from '../../../Context/DataContext';
import { notification } from 'antd';
import Tooltip from 'antd/es/tooltip';
import Select from 'antd/es/select';

type User = {
  id: number
  name: string
  email: string
  verified: boolean
  role: string
}

interface RoleProps {
  user: User
  refetch: Function
}

const roles = ["Admin", "Customer"];

const { Option } = Select;

export const Role: React.FC<RoleProps> = ({ user, refetch }) => {
  const { me } = useContext(DataContext);
  const [updateRole] = useUpdateRoleMutation();

  const handleRoleChange = async (role) => {

    const updated = await updateRole({
      variables: {
        id: user.id,
        role
      }
    });

    if (updated.data.updateRole) {
      notification.success({
        message: `Role for ${user.name} successfully updated!`
      })
      refetch();
    } else {
      notification.error({
        message: 'Could not change role!'
      })
    }
  }

  return (
    <Tooltip title={me.id === user.id ? "You can not change your own role" : "Change Role"}>
      <Select
        size={"large"}
        placeholder="Please select"
        defaultValue={user.role}
        onChange={handleRoleChange}
        style={{ width: '100%' }}
        disabled={me.id === user.id}
      >
        {roles.map((role, i) => (
          <Option key={i} value={role}>{role}</Option>
        ))}
      </Select>
    </Tooltip>
  );
}
import React, { useState, useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import Divider from 'antd/es/divider';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Table from 'antd/es/table';
import Space from 'antd/es/space';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Alert from 'antd/es/alert';
import Checkbox from 'antd/es/checkbox';
import { notification } from 'antd';
import Highlighter from 'react-highlight-words';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import CKEditor from '@ckeditor/ckeditor5-react';
import config from './config.json';
import {
  PlusOutlined,
  MinusOutlined,
  CheckOutlined,
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styles from './styles.module.scss';
import { Selection as SelectionType, useCreateSelectionMutation, useUpdateSelectionMutation } from '../../../generated/graphql';
import Popconfirm from 'antd/es/popconfirm';
import { DescEditor } from './DescEditor';

const { Paragraph } = Typography;

type DropDownType = {
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any
}

type NewSelection = {
  label: string,
  sort: number
}

type selectProps = {
  selectionsData: any,
  refetchSelection: Function,
  removeSelection: Function
}

export const Selection: React.FC<selectProps> = ({ selectionsData, refetchSelection, removeSelection }) => {
  const [createSelection] = useCreateSelectionMutation();
  const searchRef = useRef<Input>(null);
  const [query, setQuery] = useState("");
  const [column, setColumn] = useState("");
  const [updateSelection, { loading }] = useUpdateSelectionMutation();
  const [openCreate, setOpenCreate] = useState(false);

  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");
  const [sort, setSort] = useState(0);
  const [page, setPage] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const ani = useSpring({
    maxHeight: openCreate ? 500 : 0
  });

  const handleLabelChange = (e: any) => {
    setLabel(e.target.value)
  }

  // const handleDescriptionChange = (e: any) => {
  //   setDescription(e.target.value)
  // }

  const handleSortChange = (e: any) => {
    setSort(parseInt(e.target.value))
  }

  const handlePageChecked = () => {
    setPage(!page);
  }

  const onFinish = async (e: any) => {
    e.preventDefault();
    if (testType({ label, sort })) {
      const created = await createSelection({
        variables: {
          label,
          description,
          page,
          sort
        }
      });
      if (created.data.createSelection) {
        notification.success({
          message: `Select successfully created!`
        });
        setLabel("")
        setDescription("")
        setSort(0);
        refetchSelection();
      } else {
        notification.error({
          message: `Could not create Select!`
        });
      }
    } else {
      setError("Not all fields are filled correctly");
    }
  }

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setQuery(selectedKeys[0]);
    setColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setQuery("")
    setColumn("")
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: DropDownType) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined translate={"search"} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined translate={"search"} style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchRef!.current!.select());
      }
    },
    render: (text: string) =>
      column === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[query]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  const handleUpdate = (type: string, value: string | number | boolean, record: SelectionType) => {
    updateSelection({
      variables: {
        ...record,
        [type]: value
      },
      update: () => {
        refetchSelection();
      }
    });
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      sorter: (a: any, b: any) => a.label.localeCompare(b.label),
      ...getColumnSearchProps('label'),
      render: (text: any, record: any) => <Paragraph style={{ marginBottom: 0 }} editable={{ onChange: val => handleUpdate("label", val, record) }}>{text}</Paragraph>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      ellipsis: true,
      key: 'description',
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      ...getColumnSearchProps('description'),
      render: (text: any, record: any) => (
        <DescEditor text={text} setDescription={setDescription} description={description} update={handleUpdate} record={record} />
      )
    },
    {
      title: 'Sorting',
      dataIndex: 'sort',
      key: 'sort',
      width: 100,
      sorter: (a: any, b: any) => a.sort - b.sort,
      render: (text: any, record: any) => <Paragraph style={{ marginBottom: 0 }} editable={{ onChange: val => handleUpdate("sort", parseInt(val), record) }}>{text.toString()}</Paragraph>
    },
    {
      title: 'On Page',
      dataIndex: 'page',
      key: 'page',
      width: 100,
      render: (text: any, record: any) => <Checkbox checked={text} onChange={e => handleUpdate("page", e.target.checked, record)} />
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      width: 100,
      render: (item: SelectionType) => (
        <Popconfirm
          placement="left"
          title={"Are you sure?"}
          onConfirm={() => removeSelection(item.id)}
          icon={<ExclamationCircleOutlined translate={"confirm"} style={{ color: 'red' }} />}
          okText="Yes"
          cancelText="No"
        >
          <span style={{ cursor: 'pointer', color: '#1890ff' }}>
            <DeleteOutlined translate={"delete"} />
          </span>
        </Popconfirm>
      )
    },
  ];

  const testType = (t: NewSelection) => {
    // const pattern = new RegExp("^[a-z]{3,}");
    return t.label !== '' && !isNaN(t.sort);
  }

  return (
    <Card
      title={"Selection"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      className={styles.content}
      bordered={false}

      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => setOpenCreate(c => !c)}>
          {!openCreate ? <PlusOutlined translate={'open create'} /> : <MinusOutlined translate={'close create'} />}
        </Button>
      }
    >
      <animated.div style={ani} className={styles.createContainer}>
        <Row gutter={16}>
          <Col flex="200px">
            <Input size={"large"} placeholder={"Label"} allowClear onChange={handleLabelChange} value={label} onPressEnter={onFinish} required />
          </Col>
          <Col flex="400px">
            <CKEditor
              editor={BalloonEditor}
              focus={true}
              data={description}
              config={config}
              height={300}
              onInit={editor => {
                // setDescription(editor.getData());
                editor.editing.view.change(writer => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "width",
                    "100%",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "border",
                    "1px solid #d9d9d9",
                    editor.editing.view.document.getRoot()
                  );
                  writer.setStyle(
                    "border-radius",
                    "2px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event: any, editor: any) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
            {/* <Input size={"large"} placeholder={"Description"} allowClear onChange={handleDescriptionChange} value={description} onPressEnter={onFinish} required /> */}
          </Col>
          <Col flex="100px">
            <Input size={"large"} type={"number"} placeholder={"Sort"} onChange={handleSortChange} value={sort} onPressEnter={onFinish} />
          </Col>
          <Col flex="130px" className={styles.centerCol}>
            <Checkbox
              checked={page}
              onChange={handlePageChecked}
              className={styles.checkbox}
            >
              on Page?
              </Checkbox>
          </Col>
          <Col flex="50px" className={styles.rightCol}>
            <Button shape={"circle"} className={styles.button} type="primary" onClick={onFinish}>
              <CheckOutlined translate={'close create'} />
            </Button>
          </Col>
        </Row>
        {error && <Alert message={error} type="error" showIcon closable afterClose={() => setError(null)} />}
        <Divider />
      </animated.div>
      <Table rowKey="id" loading={loading} size={"small"} tableLayout={"fixed"} dataSource={selectionsData ? selectionsData.selections : []} columns={columns} />
    </Card>
  );
}

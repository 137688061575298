import React from "react";
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import styles from "./styles.module.scss";

interface modalProps {
  title: string,
  open: boolean,
  closeAction: any,
  saveAction?: any,
  wide?: boolean
  hasTitle?: boolean
}

const Modal: React.FC<modalProps> = ({ wide = false, hasTitle = true, title, open, closeAction, saveAction, children }) => {
  const handleClose = (e: any) => {
    if (e.target === e.currentTarget) {
      closeAction();
    }
  };

  return (
    open ? (
      <div className={styles.overlay} onClick={handleClose}>
        {hasTitle && (
          <div className={wide ? [styles.headline, styles.wide].join(" ") : styles.headline}>
            {title}
            <span className={styles.close} onClick={closeAction}>
              <CloseIcon width="30" fill="#fff" />
            </span>
          </div>
        )}
        {children}
      </div>
    ) : null
  );
};

export default Modal;

import React, { useContext } from 'react'
import { useRemoveUserMutation } from '../../../generated/graphql';
import { notification } from 'antd';
import Button from 'antd/es/button';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import { DataContext } from '../../../Context/DataContext';


type User = {
  id: number
  name: string
  email: string
  verified: boolean
  role: string
}

interface RemoveProps {
  user: User
  refetch: Function
}

export const Remove: React.FC<RemoveProps> = ({ user, refetch }) => {
  const { me } = useContext(DataContext);
  const [remove] = useRemoveUserMutation();

  const handleRemove = async () => {
    const removed = await remove({
      variables: {
        id: user.id
      }
    });

    if (removed.data.removeUser) {
      notification.success({
        message: `User ${user.name} successfully removed!`
      })
      refetch();
    } else {
      notification.error({
        message: 'Could not remove user!'
      })
    }
  }

  return (
    <Tooltip title={me.id === user.id ? "You can not delete yourself" : "Remove user"}>
      <Popconfirm
        title="You really want to delete the user?"
        style={{ color: 'red' }}
        onConfirm={handleRemove}
        placement="left"
        okText="Remove"
        disabled={me.id === user.id}
      >
        <Button
          danger
          shape="circle"
          disabled={me.id === user.id}
          icon={<DeleteOutlined translate={'remove user'} />}
        />
      </Popconfirm>
    </Tooltip>
  );
}
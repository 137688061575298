import React, { FormEvent } from "react";
import Input from "../Input";
import styles from "./styles.module.scss";
import { useRegisterMutation } from "../../generated/graphql";
import Loader from "../Loader";
import { notification } from "antd";

const { useState } = React;

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [dsgvo, setDsgvo] = useState(false);
  const [register, { data, loading, error }] = useRegisterMutation();
  const role = "Customer";

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const reg = await register({
      variables: {
        email,
        name,
        lastname,
        dsgvo,
        password,
        role
      }
    });

    if (reg.data.register) {
      notification.success({
        message: "Your account was successfully creaeted!",
        description: "We have sent you a verification mail to your email account."
      });
    }
  };

  return (
    <form className={styles.registerContainer} onSubmit={handleSubmit}>
      {!loading && !data && (
        <>
          <Input
            value={name}
            id="name"
            required={true}
            placeholder={"Your Name"}
            type={"text"}
            setValue={setName}
          />
          <Input
            value={lastname}
            id="lastname"
            required={true}
            placeholder={"Your Lastname"}
            type={"text"}
            setValue={setLastname}
          />
          <Input
            value={email}
            id="email"
            required={true}
            placeholder={"Your Email"}
            type={"email"}
            setValue={setEmail}
          />
          <Input
            value={password}
            id="password"
            required={true}
            placeholder={"Your Password"}
            type={"password"}
            setValue={setPassword}
          />

          <Input
            value={String(dsgvo)}
            id="dsgvo"
            required={true}
            placeholder={"Do you accept our DSGVO?"}
            type={"checkbox"}
            setValue={setDsgvo}
          />

          <button
            type={"submit"}
            className={styles.button}
            onClick={handleSubmit}
          >
            Register
          </button>
        </>
      )}
      {loading && (
        <Loader />
      )}
      {!loading && !!data && (
        <div>
          <p>Thank you for your registration!</p>
          <p>Please check your email account to verify your account.</p>
        </div>
      )}
      {!loading && error && (
        <div>Ooops, something went wrong.</div>
      )}
    </form>
  );
};

export default Register;

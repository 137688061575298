import React, { useState } from 'react'
import Button from 'antd/es/button';
import {
  PlusOutlined,
  MinusOutlined,
  FileImageOutlined,
  VideoCameraAddOutlined,
  // DeleteOutlined,
} from '@ant-design/icons';
import { useSpring, animated } from 'react-spring';

import styles from './styles.module.scss';


interface fabProps {
  action: Function
}

export const AddFab: React.FC<fabProps> = ({ action }) => {
  const [open, setOpen] = useState(false);
  const aniOne = useSpring({
    top: open ? -31 : 0,
    left: open ? 21 : 0,
  })
  const aniTwo = useSpring({
    left: open ? 40 : 0,
  })
  const aniThree = useSpring({
    top: open ? 31 : 0,
    left: open ? 21 : 0,
  })
  const aniFour = useSpring({
    top: open ? -31 : 0,
    left: open ? -21 : 0,
  })

  return (
    <div className={styles.fabContainer}>
      <Button shape={"circle"} className={styles.icon} type="primary" onClick={() => setOpen(o => !o)}>
        {!open ? <PlusOutlined translate={'open create'} /> : <MinusOutlined translate={'close create'} />}
      </Button>
      <div className={styles.typeHolder}>
        <animated.div className={styles.addContentButton} style={aniFour}>
          <Button shape={"circle"} type="primary" onClick={() => action('headline')}>
            H
          </Button>
        </animated.div>
        <animated.div className={styles.addContentButton} style={aniOne}>
          <Button shape={"circle"} type="primary" onClick={() => action('text')}>
            T
          </Button>
        </animated.div>
        <animated.div className={styles.addContentButton} style={aniTwo}>
          <Button shape={"circle"} type="primary" onClick={() => action('images')}>
            <FileImageOutlined translate={'create Image'} />
          </Button>
        </animated.div>
        <animated.div className={styles.addContentButton} style={aniThree}>
          <Button shape={"circle"} type="primary" onClick={() => action('videos')}>
            <VideoCameraAddOutlined translate={'create Video'} />
          </Button>
        </animated.div>
      </div>
    </div>
  );
}
import React, { useContext } from 'react';
import { ReactComponent as ListIcon } from './Buttons/myList.svg';
import { ReactComponent as UserIcon } from './Buttons/user.svg';
import { BaseButton } from './Buttons/BaseButton';
import styles from './actions.module.scss';
import { MyList } from '../MyList';
import { UserSettings } from '../User';
import { DataContext } from '../../Context/DataContext';

export const Actions: React.FC = () => {
  const { me } = useContext(DataContext);
  const actions = [
    {
      label: "my List",
      title: "My List",
      icon: <ListIcon fill={'#fff'} color={'#fff'} className={styles.icon} />,
      component: <MyList />
    },
    {
      label: "User",
      title: "Edit your account details",
      icon: <UserIcon fill={'#fff'} color={'#fff'} className={styles.icon} />,
      component: <UserSettings />
    }
  ]

  return (me ?
    (
      <div className={styles.actionsContainer}>
        {actions.map((action, i) => (
          <BaseButton
            key={i}
            label={action.label}
            title={action.title}
            icon={action.icon}
          >
            {action.component}
          </BaseButton>
        ))}
      </div>
    ) : null
  );
}
import React from 'react';
import { Base } from '../../generated/graphql';
import { Type } from './Type';

import styles from './types.module.scss';

interface typesProps {
  bases: Base[]
  radius?: number,
  gap?: number
}

export const Types: React.FC<typesProps> = ({ bases, radius = 10, gap = 0.2 }) => {
  const types = ["Type 1", "Type 2", "Type 3", "Type 4"]

  return (
    <div className={styles.typesContainer} style={{ gridGap: `${gap}rem` }}>
      {types.map((t, i) => (
        <Type key={i} type={t} radius={radius} active={bases && bases.filter(b => b.type === t).length > 0 ? true : false} />
      ))}
    </div>
  );
}
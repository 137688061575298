import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined
} from '@ant-design/icons';

interface DocumentsProps {
  docs: string[]
  set: Function
}

export const Documents: React.FC<DocumentsProps> = ({ docs, set }) => {
  const [documents, setDocuments] = useState(docs);

  const handleRemove = (doc) => {
    const rIndex = docs.indexOf(doc);
    docs.splice(rIndex, 1);
    setDocuments([...docs]);
    set([...docs]);
  }

  useEffect(() => {
    if (documents !== docs) {
      set(docs);
    }
  }, [documents, docs]); // eslint-disable-line

  return (
    <div className={"container"}>
      {docs && docs.map((d, i) => (
        <div key={i}>{d.replace("/files/documents/", "")} <DeleteOutlined translate={"remove"} style={{ fill: '#ff0000', color: '#ff0000' }} onClick={() => handleRemove(d)} /></div>
      ))}
    </div>
  );
}
import React, { useEffect, useState } from 'react';
import { SavedConfig, useSavedConfigMutation } from '../../generated/graphql';

interface ConfigProps {
  savedConfig: SavedConfig
}

export const Config: React.FC<ConfigProps> = ({ savedConfig }) => {
  const [config, setConfig] = useState(null);
  const [loadConfig] = useSavedConfigMutation();

  const getConfig = async () => {
    const load = await loadConfig({
      variables: {
        uri: savedConfig.uri
      }
    })

    setConfig(load.data.config)
  }

  useEffect(() => {
    getConfig();
  }, []) // eslint-disable-line

  return (config && (
    <div>
      <strong style={{ color: 'rgba(0, 149, 138, 1)' }}>{config.name}</strong>
      {config.properties && config.properties.map((prop, i) => (
        <div key={i}><strong>{prop.name}:</strong> {prop.value}</div>
      ))}
    </div>
  ));
}
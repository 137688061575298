import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Base = {
  __typename?: 'Base';
  id: Scalars['Int'];
  name: Scalars['String'];
  collection: Scalars['String'];
  type: Scalars['String'];
  video?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Scalars['String']>>;
  properties: Array<Properties>;
  contents: Array<Content>;
};

export type Collection = {
  __typename?: 'Collection';
  name: Scalars['String'];
  image: Scalars['String'];
};

export type Configuration = {
  __typename?: 'Configuration';
  id: Scalars['Int'];
  name: Scalars['String'];
  video?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Scalars['String']>>;
  collection: Scalars['String'];
  properties: Array<Properties>;
  bases?: Maybe<Array<Base>>;
  contents: Array<Content>;
};

export type Content = {
  __typename?: 'Content';
  id: Scalars['String'];
  type: Scalars['String'];
  content: Scalars['String'];
  align: Scalars['String'];
  sort: Scalars['Int'];
  configuration: Configuration;
  base: Base;
};

export type Files = {
  __typename?: 'Files';
  images?: Maybe<Array<Scalars['String']>>;
  videos?: Maybe<Array<Scalars['String']>>;
  documents?: Maybe<Array<Scalars['String']>>;
  collections?: Maybe<Array<Collection>>;
};

export type InputContents = {
  id: Scalars['String'];
  type: Scalars['String'];
  content: Scalars['String'];
  align: Scalars['String'];
  sort: Scalars['Int'];
  configuration?: Maybe<Scalars['Int']>;
  base?: Maybe<Scalars['Int']>;
};

export type InputFile = {
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type InputProperties = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type IntroductionStep = {
  __typename?: 'IntroductionStep';
  id: Scalars['Float'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  text: Scalars['String'];
  image: Scalars['String'];
  icon: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  logout: Scalars['Boolean'];
  revokeRefreshTokensForUser: Scalars['Boolean'];
  updateRole: Scalars['Boolean'];
  updateVerify: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  login: LoginResponse;
  register: Scalars['Boolean'];
  removeUser: Scalars['Boolean'];
  config: Configuration;
  createSavedConfig: Output;
  sendConfigs: Scalars['Boolean'];
  removeSavedConfig: Scalars['Boolean'];
  removeSavedConfigs: Scalars['Boolean'];
  createOption: Scalars['Boolean'];
  updateOption: Scalars['Boolean'];
  removeOption: Scalars['Boolean'];
  createConfiguration: Scalars['Boolean'];
  updateConfiguration: Scalars['Boolean'];
  removeConfiguration: Scalars['Boolean'];
  createBase: Scalars['Boolean'];
  updateBase: Scalars['Boolean'];
  removeBase: Scalars['Boolean'];
  createSelection: Scalars['Boolean'];
  createSelections: Scalars['Boolean'];
  updateSelection: Scalars['Boolean'];
  removeSelection: Scalars['Boolean'];
  removeFile: Scalars['Boolean'];
  removeCollection: Scalars['Boolean'];
  uploadFiles: Scalars['Boolean'];
  updateStep: Scalars['Boolean'];
  createStep: Scalars['Boolean'];
  removeStep: Scalars['Boolean'];
  updateSetting: Scalars['Boolean'];
};


export type MutationRevokeRefreshTokensForUserArgs = {
  userId: Scalars['Int'];
};


export type MutationUpdateRoleArgs = {
  role: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationUpdateVerifyArgs = {
  verified: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type MutationUpdateUserArgs = {
  dsgvo: Scalars['Boolean'];
  email: Scalars['String'];
  lastname: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationRegisterArgs = {
  role: Scalars['String'];
  password: Scalars['String'];
  dsgvo: Scalars['Boolean'];
  email: Scalars['String'];
  lastname: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationConfigArgs = {
  uri: Scalars['String'];
};


export type MutationCreateSavedConfigArgs = {
  config: Scalars['Float'];
  name: Scalars['String'];
};


export type MutationSendConfigsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRemoveSavedConfigArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSavedConfigsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationCreateOptionArgs = {
  sort: Scalars['Float'];
  selectorId?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};


export type MutationUpdateOptionArgs = {
  sort: Scalars['Float'];
  selectorId: Scalars['Float'];
  name: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationRemoveOptionArgs = {
  id: Scalars['Float'];
};


export type MutationCreateConfigurationArgs = {
  documents?: Maybe<Array<Scalars['String']>>;
  video?: Maybe<Scalars['String']>;
  bases: Array<Scalars['Int']>;
  contents: Array<InputContents>;
  properties: Array<InputProperties>;
  collection: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateConfigurationArgs = {
  documents?: Maybe<Array<Scalars['String']>>;
  video?: Maybe<Scalars['String']>;
  bases: Array<Scalars['Int']>;
  contents: Array<InputContents>;
  properties: Array<InputProperties>;
  collection: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationRemoveConfigurationArgs = {
  id: Scalars['Float'];
};


export type MutationCreateBaseArgs = {
  documents?: Maybe<Array<Scalars['String']>>;
  contents: Array<InputContents>;
  properties: Array<InputProperties>;
  video?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  collection: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateBaseArgs = {
  documents?: Maybe<Array<Scalars['String']>>;
  contents: Array<InputContents>;
  properties: Array<InputProperties>;
  video?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  collection: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationRemoveBaseArgs = {
  id: Scalars['Float'];
};


export type MutationCreateSelectionArgs = {
  sort: Scalars['Float'];
  page: Scalars['Boolean'];
  description: Scalars['String'];
  label: Scalars['String'];
};


export type MutationCreateSelectionsArgs = {
  config: Scalars['String'];
};


export type MutationUpdateSelectionArgs = {
  sort: Scalars['Float'];
  page: Scalars['Boolean'];
  description: Scalars['String'];
  label: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationRemoveSelectionArgs = {
  id: Scalars['Float'];
};


export type MutationRemoveFileArgs = {
  file: Scalars['String'];
};


export type MutationRemoveCollectionArgs = {
  collection: Scalars['String'];
};


export type MutationUploadFilesArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationUpdateStepArgs = {
  icon: Scalars['String'];
  text: Scalars['String'];
  image: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationCreateStepArgs = {
  icon: Scalars['String'];
  text: Scalars['String'];
  image: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};


export type MutationRemoveStepArgs = {
  id: Scalars['Float'];
};


export type MutationUpdateSettingArgs = {
  value: Scalars['String'];
  id: Scalars['Float'];
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['Int'];
  name: Scalars['String'];
  selector: Selection;
  sort: Scalars['Int'];
};

export type Output = {
  __typename?: 'Output';
  name: Scalars['String'];
  uri: Scalars['String'];
};

export type Properties = {
  __typename?: 'Properties';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  users: Array<User>;
  me?: Maybe<User>;
  verifyUser: Scalars['Boolean'];
  savedConfigs: Array<SavedConfig>;
  options: Array<Option>;
  filterOptions: Array<Option>;
  configurations: Array<Configuration>;
  bases: Array<Base>;
  basesWithoutRelation: Array<Base>;
  selections: Array<Selection>;
  filesByType?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<Collection>>;
  steps: Array<IntroductionStep>;
  settings: Array<Settings>;
};


export type QueryVerifyUserArgs = {
  token: Scalars['String'];
};


export type QueryFilesByTypeArgs = {
  type: Scalars['String'];
};


export type QueryThumbnailArgs = {
  file: Scalars['String'];
};

export type SavedConfig = {
  __typename?: 'SavedConfig';
  id: Scalars['String'];
  name: Scalars['String'];
  uri: Scalars['String'];
  config: Scalars['Int'];
  user: User;
};

export type Selection = {
  __typename?: 'Selection';
  id: Scalars['Int'];
  label: Scalars['String'];
  page: Scalars['Boolean'];
  description: Scalars['String'];
  options: Array<Option>;
  sort: Scalars['Int'];
};

export type Settings = {
  __typename?: 'Settings';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateRoleInput = {
  role: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  name: Scalars['String'];
  lastname: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  dsgvo: Scalars['Boolean'];
  verified: Scalars['Boolean'];
  verificationToken: Scalars['String'];
  role: Scalars['String'];
  savedConfigs: Array<SavedConfig>;
};

export type BasesQueryVariables = Exact<{ [key: string]: never; }>;


export type BasesQuery = (
  { __typename?: 'Query' }
  & { bases: Array<(
    { __typename?: 'Base' }
    & Pick<Base, 'id' | 'name' | 'collection' | 'type' | 'video' | 'documents'>
    & { properties: Array<(
      { __typename?: 'Properties' }
      & Pick<Properties, 'name' | 'value'>
    )>, contents: Array<(
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'type' | 'content' | 'align' | 'sort'>
    )> }
  )> }
);

export type BasesWithoutRelationQueryVariables = Exact<{ [key: string]: never; }>;


export type BasesWithoutRelationQuery = (
  { __typename?: 'Query' }
  & { basesWithoutRelation: Array<(
    { __typename?: 'Base' }
    & Pick<Base, 'id' | 'name'>
  )> }
);

export type CreateBaseMutationVariables = Exact<{
  name: Scalars['String'];
  collection: Scalars['String'];
  type: Scalars['String'];
  video?: Maybe<Scalars['String']>;
  properties: Array<InputProperties>;
  contents: Array<InputContents>;
  documents?: Maybe<Array<Scalars['String']>>;
}>;


export type CreateBaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBase'>
);

export type RemoveBaseMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveBaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBase'>
);

export type UpdateBaseMutationVariables = Exact<{
  id: Scalars['Float'];
  name: Scalars['String'];
  collection: Scalars['String'];
  type: Scalars['String'];
  video?: Maybe<Scalars['String']>;
  properties: Array<InputProperties>;
  contents: Array<InputContents>;
  documents?: Maybe<Array<Scalars['String']>>;
}>;


export type UpdateBaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBase'>
);

export type ConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigurationsQuery = (
  { __typename?: 'Query' }
  & { configurations: Array<(
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'name' | 'collection' | 'video' | 'documents'>
    & { properties: Array<(
      { __typename?: 'Properties' }
      & Pick<Properties, 'name' | 'value'>
    )>, contents: Array<(
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'type' | 'content' | 'align' | 'sort'>
    )>, bases?: Maybe<Array<(
      { __typename?: 'Base' }
      & Pick<Base, 'id' | 'name' | 'collection' | 'type'>
    )>> }
  )> }
);

export type CreateConfigurationMutationVariables = Exact<{
  name: Scalars['String'];
  collection: Scalars['String'];
  properties: Array<InputProperties>;
  contents: Array<InputContents>;
  bases: Array<Scalars['Int']>;
  video?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Scalars['String']>>;
}>;


export type CreateConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createConfiguration'>
);

export type RemoveConfigurationMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeConfiguration'>
);

export type UpdateConfigMutationVariables = Exact<{
  id: Scalars['Float'];
  name: Scalars['String'];
  collection: Scalars['String'];
  properties: Array<InputProperties>;
  contents: Array<InputContents>;
  bases: Array<Scalars['Int']>;
  video?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Scalars['String']>>;
}>;


export type UpdateConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateConfiguration'>
);

export type CollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CollectionsQuery = (
  { __typename?: 'Query' }
  & { collections?: Maybe<Array<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'name' | 'image'>
  )>> }
);

export type FilesByTypeQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type FilesByTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'filesByType'>
);

export type RemoveCollectionMutationVariables = Exact<{
  collection: Scalars['String'];
}>;


export type RemoveCollectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeCollection'>
);

export type RemoveFileMutationVariables = Exact<{
  file: Scalars['String'];
}>;


export type RemoveFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeFile'>
);

export type ThumbnailQueryVariables = Exact<{
  file: Scalars['String'];
}>;


export type ThumbnailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'thumbnail'>
);

export type UploadFilesMutationVariables = Exact<{
  files: Array<Scalars['Upload']>;
}>;


export type UploadFilesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFiles'>
);

export type CreateStepMutationVariables = Exact<{
  title: Scalars['String'];
  subtitle: Scalars['String'];
  image: Scalars['String'];
  text: Scalars['String'];
  icon: Scalars['String'];
}>;


export type CreateStepMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createStep'>
);

export type RemoveStepMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveStepMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeStep'>
);

export type StepsQueryVariables = Exact<{ [key: string]: never; }>;


export type StepsQuery = (
  { __typename?: 'Query' }
  & { steps: Array<(
    { __typename?: 'IntroductionStep' }
    & Pick<IntroductionStep, 'id' | 'title' | 'subtitle' | 'image' | 'text' | 'icon'>
  )> }
);

export type UpdateStepMutationVariables = Exact<{
  id: Scalars['Float'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  image: Scalars['String'];
  text: Scalars['String'];
  icon: Scalars['String'];
}>;


export type UpdateStepMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateStep'>
);

export type CreateOptionMutationVariables = Exact<{
  name: Scalars['String'];
  selectorId?: Maybe<Scalars['Float']>;
  sort: Scalars['Float'];
}>;


export type CreateOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOption'>
);

export type FilterOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FilterOptionsQuery = (
  { __typename?: 'Query' }
  & { filterOptions: Array<(
    { __typename?: 'Option' }
    & Pick<Option, 'id' | 'name' | 'sort'>
    & { selector: (
      { __typename?: 'Selection' }
      & Pick<Selection, 'id' | 'label' | 'description' | 'page' | 'sort'>
    ) }
  )> }
);

export type OptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type OptionsQuery = (
  { __typename?: 'Query' }
  & { options: Array<(
    { __typename?: 'Option' }
    & Pick<Option, 'id' | 'name' | 'sort'>
    & { selector: (
      { __typename?: 'Selection' }
      & Pick<Selection, 'id' | 'label'>
    ) }
  )> }
);

export type RemoveOptionMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOption'>
);

export type UpdateOptionMutationVariables = Exact<{
  id: Scalars['Float'];
  name: Scalars['String'];
  selectorId: Scalars['Float'];
  sort: Scalars['Float'];
}>;


export type UpdateOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOption'>
);

export type SavedConfigMutationVariables = Exact<{
  uri: Scalars['String'];
}>;


export type SavedConfigMutation = (
  { __typename?: 'Mutation' }
  & { config: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'id' | 'name' | 'collection' | 'video' | 'documents'>
    & { properties: Array<(
      { __typename?: 'Properties' }
      & Pick<Properties, 'name' | 'value'>
    )>, contents: Array<(
      { __typename?: 'Content' }
      & Pick<Content, 'id' | 'type' | 'content' | 'align' | 'sort'>
    )>, bases?: Maybe<Array<(
      { __typename?: 'Base' }
      & Pick<Base, 'id' | 'name' | 'collection'>
    )>> }
  ) }
);

export type CreateSavedConfigMutationVariables = Exact<{
  name: Scalars['String'];
  config: Scalars['Float'];
}>;


export type CreateSavedConfigMutation = (
  { __typename?: 'Mutation' }
  & { createSavedConfig: (
    { __typename?: 'Output' }
    & Pick<Output, 'name' | 'uri'>
  ) }
);

export type RemoveSavedConfigMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveSavedConfigMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSavedConfig'>
);

export type RemoveSavedConfigsMutationVariables = Exact<{
  ids: Array<Scalars['String']>;
}>;


export type RemoveSavedConfigsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSavedConfigs'>
);

export type SavedConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type SavedConfigsQuery = (
  { __typename?: 'Query' }
  & { savedConfigs: Array<(
    { __typename?: 'SavedConfig' }
    & Pick<SavedConfig, 'id' | 'name' | 'uri' | 'config'>
  )> }
);

export type SendConfigsMutationVariables = Exact<{
  ids: Array<Scalars['String']>;
}>;


export type SendConfigsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendConfigs'>
);

export type CreateSelectionMutationVariables = Exact<{
  label: Scalars['String'];
  description: Scalars['String'];
  sort: Scalars['Float'];
  page: Scalars['Boolean'];
}>;


export type CreateSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSelection'>
);

export type CreateSelectionsMutationVariables = Exact<{
  config: Scalars['String'];
}>;


export type CreateSelectionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSelections'>
);

export type RemoveSelectionMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type RemoveSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSelection'>
);

export type SelectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SelectionsQuery = (
  { __typename?: 'Query' }
  & { selections: Array<(
    { __typename?: 'Selection' }
    & Pick<Selection, 'id' | 'label' | 'description' | 'page' | 'sort'>
  )> }
);

export type UpdateSelectionMutationVariables = Exact<{
  id: Scalars['Int'];
  label: Scalars['String'];
  description: Scalars['String'];
  sort: Scalars['Float'];
  page: Scalars['Boolean'];
}>;


export type UpdateSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSelection'>
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { settings: Array<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'id' | 'name' | 'value'>
  )> }
);

export type UpdateSettingMutationVariables = Exact<{
  id: Scalars['Float'];
  value: Scalars['String'];
}>;


export type UpdateSettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSetting'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken' | 'refreshToken'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'lastname' | 'email' | 'dsgvo' | 'role'>
    ) }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'lastname' | 'email' | 'dsgvo' | 'role'>
  )> }
);

export type RegisterMutationVariables = Exact<{
  name: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  dsgvo: Scalars['Boolean'];
  password: Scalars['String'];
  role: Scalars['String'];
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUser'>
);

export type RevokeTokenMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type RevokeTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'revokeRefreshTokensForUser'>
);

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['Int'];
  role: Scalars['String'];
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRole'>
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  dsgvo: Scalars['Boolean'];
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type UpdateVerifyMutationVariables = Exact<{
  id: Scalars['Int'];
  verified: Scalars['Boolean'];
}>;


export type UpdateVerifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateVerify'>
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'lastname' | 'email' | 'verified' | 'role'>
  )> }
);

export type VerifyUserQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyUser'>
);


export const BasesDocument = gql`
    query Bases {
  bases {
    id
    name
    collection
    type
    video
    documents
    properties {
      name
      value
    }
    contents {
      id
      type
      content
      align
      sort
    }
  }
}
    `;

/**
 * __useBasesQuery__
 *
 * To run a query within a React component, call `useBasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BasesQuery, BasesQueryVariables>) {
        return ApolloReactHooks.useQuery<BasesQuery, BasesQueryVariables>(BasesDocument, baseOptions);
      }
export function useBasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasesQuery, BasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BasesQuery, BasesQueryVariables>(BasesDocument, baseOptions);
        }
export type BasesQueryHookResult = ReturnType<typeof useBasesQuery>;
export type BasesLazyQueryHookResult = ReturnType<typeof useBasesLazyQuery>;
export type BasesQueryResult = ApolloReactCommon.QueryResult<BasesQuery, BasesQueryVariables>;
export const BasesWithoutRelationDocument = gql`
    query BasesWithoutRelation {
  basesWithoutRelation {
    id
    name
  }
}
    `;

/**
 * __useBasesWithoutRelationQuery__
 *
 * To run a query within a React component, call `useBasesWithoutRelationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasesWithoutRelationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasesWithoutRelationQuery({
 *   variables: {
 *   },
 * });
 */
export function useBasesWithoutRelationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BasesWithoutRelationQuery, BasesWithoutRelationQueryVariables>) {
        return ApolloReactHooks.useQuery<BasesWithoutRelationQuery, BasesWithoutRelationQueryVariables>(BasesWithoutRelationDocument, baseOptions);
      }
export function useBasesWithoutRelationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasesWithoutRelationQuery, BasesWithoutRelationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BasesWithoutRelationQuery, BasesWithoutRelationQueryVariables>(BasesWithoutRelationDocument, baseOptions);
        }
export type BasesWithoutRelationQueryHookResult = ReturnType<typeof useBasesWithoutRelationQuery>;
export type BasesWithoutRelationLazyQueryHookResult = ReturnType<typeof useBasesWithoutRelationLazyQuery>;
export type BasesWithoutRelationQueryResult = ApolloReactCommon.QueryResult<BasesWithoutRelationQuery, BasesWithoutRelationQueryVariables>;
export const CreateBaseDocument = gql`
    mutation CreateBase($name: String!, $collection: String!, $type: String!, $video: String, $properties: [InputProperties!]!, $contents: [InputContents!]!, $documents: [String!]) {
  createBase(name: $name, collection: $collection, type: $type, video: $video, properties: $properties, contents: $contents, documents: $documents)
}
    `;
export type CreateBaseMutationFn = ApolloReactCommon.MutationFunction<CreateBaseMutation, CreateBaseMutationVariables>;

/**
 * __useCreateBaseMutation__
 *
 * To run a mutation, you first call `useCreateBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaseMutation, { data, loading, error }] = useCreateBaseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      collection: // value for 'collection'
 *      type: // value for 'type'
 *      video: // value for 'video'
 *      properties: // value for 'properties'
 *      contents: // value for 'contents'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useCreateBaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBaseMutation, CreateBaseMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBaseMutation, CreateBaseMutationVariables>(CreateBaseDocument, baseOptions);
      }
export type CreateBaseMutationHookResult = ReturnType<typeof useCreateBaseMutation>;
export type CreateBaseMutationResult = ApolloReactCommon.MutationResult<CreateBaseMutation>;
export type CreateBaseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBaseMutation, CreateBaseMutationVariables>;
export const RemoveBaseDocument = gql`
    mutation RemoveBase($id: Float!) {
  removeBase(id: $id)
}
    `;
export type RemoveBaseMutationFn = ApolloReactCommon.MutationFunction<RemoveBaseMutation, RemoveBaseMutationVariables>;

/**
 * __useRemoveBaseMutation__
 *
 * To run a mutation, you first call `useRemoveBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBaseMutation, { data, loading, error }] = useRemoveBaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveBaseMutation, RemoveBaseMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveBaseMutation, RemoveBaseMutationVariables>(RemoveBaseDocument, baseOptions);
      }
export type RemoveBaseMutationHookResult = ReturnType<typeof useRemoveBaseMutation>;
export type RemoveBaseMutationResult = ApolloReactCommon.MutationResult<RemoveBaseMutation>;
export type RemoveBaseMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveBaseMutation, RemoveBaseMutationVariables>;
export const UpdateBaseDocument = gql`
    mutation UpdateBase($id: Float!, $name: String!, $collection: String!, $type: String!, $video: String, $properties: [InputProperties!]!, $contents: [InputContents!]!, $documents: [String!]) {
  updateBase(id: $id, name: $name, collection: $collection, type: $type, video: $video, properties: $properties, contents: $contents, documents: $documents)
}
    `;
export type UpdateBaseMutationFn = ApolloReactCommon.MutationFunction<UpdateBaseMutation, UpdateBaseMutationVariables>;

/**
 * __useUpdateBaseMutation__
 *
 * To run a mutation, you first call `useUpdateBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaseMutation, { data, loading, error }] = useUpdateBaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      collection: // value for 'collection'
 *      type: // value for 'type'
 *      video: // value for 'video'
 *      properties: // value for 'properties'
 *      contents: // value for 'contents'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useUpdateBaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBaseMutation, UpdateBaseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBaseMutation, UpdateBaseMutationVariables>(UpdateBaseDocument, baseOptions);
      }
export type UpdateBaseMutationHookResult = ReturnType<typeof useUpdateBaseMutation>;
export type UpdateBaseMutationResult = ApolloReactCommon.MutationResult<UpdateBaseMutation>;
export type UpdateBaseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBaseMutation, UpdateBaseMutationVariables>;
export const ConfigurationsDocument = gql`
    query Configurations {
  configurations {
    id
    name
    collection
    video
    documents
    properties {
      name
      value
    }
    contents {
      id
      type
      content
      align
      sort
    }
    bases {
      id
      name
      collection
      type
    }
  }
}
    `;

/**
 * __useConfigurationsQuery__
 *
 * To run a query within a React component, call `useConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigurationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, baseOptions);
      }
export function useConfigurationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigurationsQuery, ConfigurationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfigurationsQuery, ConfigurationsQueryVariables>(ConfigurationsDocument, baseOptions);
        }
export type ConfigurationsQueryHookResult = ReturnType<typeof useConfigurationsQuery>;
export type ConfigurationsLazyQueryHookResult = ReturnType<typeof useConfigurationsLazyQuery>;
export type ConfigurationsQueryResult = ApolloReactCommon.QueryResult<ConfigurationsQuery, ConfigurationsQueryVariables>;
export const CreateConfigurationDocument = gql`
    mutation CreateConfiguration($name: String!, $collection: String!, $properties: [InputProperties!]!, $contents: [InputContents!]!, $bases: [Int!]!, $video: String, $documents: [String!]) {
  createConfiguration(name: $name, collection: $collection, properties: $properties, contents: $contents, bases: $bases, video: $video, documents: $documents)
}
    `;
export type CreateConfigurationMutationFn = ApolloReactCommon.MutationFunction<CreateConfigurationMutation, CreateConfigurationMutationVariables>;

/**
 * __useCreateConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigurationMutation, { data, loading, error }] = useCreateConfigurationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      collection: // value for 'collection'
 *      properties: // value for 'properties'
 *      contents: // value for 'contents'
 *      bases: // value for 'bases'
 *      video: // value for 'video'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useCreateConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConfigurationMutation, CreateConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConfigurationMutation, CreateConfigurationMutationVariables>(CreateConfigurationDocument, baseOptions);
      }
export type CreateConfigurationMutationHookResult = ReturnType<typeof useCreateConfigurationMutation>;
export type CreateConfigurationMutationResult = ApolloReactCommon.MutationResult<CreateConfigurationMutation>;
export type CreateConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConfigurationMutation, CreateConfigurationMutationVariables>;
export const RemoveConfigurationDocument = gql`
    mutation RemoveConfiguration($id: Float!) {
  removeConfiguration(id: $id)
}
    `;
export type RemoveConfigurationMutationFn = ApolloReactCommon.MutationFunction<RemoveConfigurationMutation, RemoveConfigurationMutationVariables>;

/**
 * __useRemoveConfigurationMutation__
 *
 * To run a mutation, you first call `useRemoveConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConfigurationMutation, { data, loading, error }] = useRemoveConfigurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveConfigurationMutation, RemoveConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveConfigurationMutation, RemoveConfigurationMutationVariables>(RemoveConfigurationDocument, baseOptions);
      }
export type RemoveConfigurationMutationHookResult = ReturnType<typeof useRemoveConfigurationMutation>;
export type RemoveConfigurationMutationResult = ApolloReactCommon.MutationResult<RemoveConfigurationMutation>;
export type RemoveConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveConfigurationMutation, RemoveConfigurationMutationVariables>;
export const UpdateConfigDocument = gql`
    mutation UpdateConfig($id: Float!, $name: String!, $collection: String!, $properties: [InputProperties!]!, $contents: [InputContents!]!, $bases: [Int!]!, $video: String, $documents: [String!]) {
  updateConfiguration(id: $id, name: $name, collection: $collection, properties: $properties, contents: $contents, bases: $bases, video: $video, documents: $documents)
}
    `;
export type UpdateConfigMutationFn = ApolloReactCommon.MutationFunction<UpdateConfigMutation, UpdateConfigMutationVariables>;

/**
 * __useUpdateConfigMutation__
 *
 * To run a mutation, you first call `useUpdateConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigMutation, { data, loading, error }] = useUpdateConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      collection: // value for 'collection'
 *      properties: // value for 'properties'
 *      contents: // value for 'contents'
 *      bases: // value for 'bases'
 *      video: // value for 'video'
 *      documents: // value for 'documents'
 *   },
 * });
 */
export function useUpdateConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConfigMutation, UpdateConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConfigMutation, UpdateConfigMutationVariables>(UpdateConfigDocument, baseOptions);
      }
export type UpdateConfigMutationHookResult = ReturnType<typeof useUpdateConfigMutation>;
export type UpdateConfigMutationResult = ApolloReactCommon.MutationResult<UpdateConfigMutation>;
export type UpdateConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConfigMutation, UpdateConfigMutationVariables>;
export const CollectionsDocument = gql`
    query Collections {
  collections {
    name
    image
  }
}
    `;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
      }
export function useCollectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = ApolloReactCommon.QueryResult<CollectionsQuery, CollectionsQueryVariables>;
export const FilesByTypeDocument = gql`
    query FilesByType($type: String!) {
  filesByType(type: $type)
}
    `;

/**
 * __useFilesByTypeQuery__
 *
 * To run a query within a React component, call `useFilesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useFilesByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FilesByTypeQuery, FilesByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<FilesByTypeQuery, FilesByTypeQueryVariables>(FilesByTypeDocument, baseOptions);
      }
export function useFilesByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilesByTypeQuery, FilesByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FilesByTypeQuery, FilesByTypeQueryVariables>(FilesByTypeDocument, baseOptions);
        }
export type FilesByTypeQueryHookResult = ReturnType<typeof useFilesByTypeQuery>;
export type FilesByTypeLazyQueryHookResult = ReturnType<typeof useFilesByTypeLazyQuery>;
export type FilesByTypeQueryResult = ApolloReactCommon.QueryResult<FilesByTypeQuery, FilesByTypeQueryVariables>;
export const RemoveCollectionDocument = gql`
    mutation RemoveCollection($collection: String!) {
  removeCollection(collection: $collection)
}
    `;
export type RemoveCollectionMutationFn = ApolloReactCommon.MutationFunction<RemoveCollectionMutation, RemoveCollectionMutationVariables>;

/**
 * __useRemoveCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollectionMutation, { data, loading, error }] = useRemoveCollectionMutation({
 *   variables: {
 *      collection: // value for 'collection'
 *   },
 * });
 */
export function useRemoveCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCollectionMutation, RemoveCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCollectionMutation, RemoveCollectionMutationVariables>(RemoveCollectionDocument, baseOptions);
      }
export type RemoveCollectionMutationHookResult = ReturnType<typeof useRemoveCollectionMutation>;
export type RemoveCollectionMutationResult = ApolloReactCommon.MutationResult<RemoveCollectionMutation>;
export type RemoveCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCollectionMutation, RemoveCollectionMutationVariables>;
export const RemoveFileDocument = gql`
    mutation RemoveFile($file: String!) {
  removeFile(file: $file)
}
    `;
export type RemoveFileMutationFn = ApolloReactCommon.MutationFunction<RemoveFileMutation, RemoveFileMutationVariables>;

/**
 * __useRemoveFileMutation__
 *
 * To run a mutation, you first call `useRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileMutation, { data, loading, error }] = useRemoveFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useRemoveFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFileMutation, RemoveFileMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFileMutation, RemoveFileMutationVariables>(RemoveFileDocument, baseOptions);
      }
export type RemoveFileMutationHookResult = ReturnType<typeof useRemoveFileMutation>;
export type RemoveFileMutationResult = ApolloReactCommon.MutationResult<RemoveFileMutation>;
export type RemoveFileMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFileMutation, RemoveFileMutationVariables>;
export const ThumbnailDocument = gql`
    query Thumbnail($file: String!) {
  thumbnail(file: $file)
}
    `;

/**
 * __useThumbnailQuery__
 *
 * To run a query within a React component, call `useThumbnailQuery` and pass it any options that fit your needs.
 * When your component renders, `useThumbnailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThumbnailQuery({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useThumbnailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ThumbnailQuery, ThumbnailQueryVariables>) {
        return ApolloReactHooks.useQuery<ThumbnailQuery, ThumbnailQueryVariables>(ThumbnailDocument, baseOptions);
      }
export function useThumbnailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ThumbnailQuery, ThumbnailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ThumbnailQuery, ThumbnailQueryVariables>(ThumbnailDocument, baseOptions);
        }
export type ThumbnailQueryHookResult = ReturnType<typeof useThumbnailQuery>;
export type ThumbnailLazyQueryHookResult = ReturnType<typeof useThumbnailLazyQuery>;
export type ThumbnailQueryResult = ApolloReactCommon.QueryResult<ThumbnailQuery, ThumbnailQueryVariables>;
export const UploadFilesDocument = gql`
    mutation UploadFiles($files: [Upload!]!) {
  uploadFiles(files: $files)
}
    `;
export type UploadFilesMutationFn = ApolloReactCommon.MutationFunction<UploadFilesMutation, UploadFilesMutationVariables>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadFilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument, baseOptions);
      }
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = ApolloReactCommon.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadFilesMutation, UploadFilesMutationVariables>;
export const CreateStepDocument = gql`
    mutation CreateStep($title: String!, $subtitle: String!, $image: String!, $text: String!, $icon: String!) {
  createStep(title: $title, subtitle: $subtitle, image: $image, text: $text, icon: $icon)
}
    `;
export type CreateStepMutationFn = ApolloReactCommon.MutationFunction<CreateStepMutation, CreateStepMutationVariables>;

/**
 * __useCreateStepMutation__
 *
 * To run a mutation, you first call `useCreateStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStepMutation, { data, loading, error }] = useCreateStepMutation({
 *   variables: {
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      image: // value for 'image'
 *      text: // value for 'text'
 *      icon: // value for 'icon'
 *   },
 * });
 */
export function useCreateStepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStepMutation, CreateStepMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStepMutation, CreateStepMutationVariables>(CreateStepDocument, baseOptions);
      }
export type CreateStepMutationHookResult = ReturnType<typeof useCreateStepMutation>;
export type CreateStepMutationResult = ApolloReactCommon.MutationResult<CreateStepMutation>;
export type CreateStepMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStepMutation, CreateStepMutationVariables>;
export const RemoveStepDocument = gql`
    mutation RemoveStep($id: Float!) {
  removeStep(id: $id)
}
    `;
export type RemoveStepMutationFn = ApolloReactCommon.MutationFunction<RemoveStepMutation, RemoveStepMutationVariables>;

/**
 * __useRemoveStepMutation__
 *
 * To run a mutation, you first call `useRemoveStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStepMutation, { data, loading, error }] = useRemoveStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveStepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveStepMutation, RemoveStepMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveStepMutation, RemoveStepMutationVariables>(RemoveStepDocument, baseOptions);
      }
export type RemoveStepMutationHookResult = ReturnType<typeof useRemoveStepMutation>;
export type RemoveStepMutationResult = ApolloReactCommon.MutationResult<RemoveStepMutation>;
export type RemoveStepMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveStepMutation, RemoveStepMutationVariables>;
export const StepsDocument = gql`
    query Steps {
  steps {
    id
    title
    subtitle
    image
    text
    icon
  }
}
    `;

/**
 * __useStepsQuery__
 *
 * To run a query within a React component, call `useStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStepsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StepsQuery, StepsQueryVariables>) {
        return ApolloReactHooks.useQuery<StepsQuery, StepsQueryVariables>(StepsDocument, baseOptions);
      }
export function useStepsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StepsQuery, StepsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StepsQuery, StepsQueryVariables>(StepsDocument, baseOptions);
        }
export type StepsQueryHookResult = ReturnType<typeof useStepsQuery>;
export type StepsLazyQueryHookResult = ReturnType<typeof useStepsLazyQuery>;
export type StepsQueryResult = ApolloReactCommon.QueryResult<StepsQuery, StepsQueryVariables>;
export const UpdateStepDocument = gql`
    mutation UpdateStep($id: Float!, $title: String!, $subtitle: String!, $image: String!, $text: String!, $icon: String!) {
  updateStep(id: $id, title: $title, subtitle: $subtitle, image: $image, text: $text, icon: $icon)
}
    `;
export type UpdateStepMutationFn = ApolloReactCommon.MutationFunction<UpdateStepMutation, UpdateStepMutationVariables>;

/**
 * __useUpdateStepMutation__
 *
 * To run a mutation, you first call `useUpdateStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStepMutation, { data, loading, error }] = useUpdateStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      image: // value for 'image'
 *      text: // value for 'text'
 *      icon: // value for 'icon'
 *   },
 * });
 */
export function useUpdateStepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStepMutation, UpdateStepMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStepMutation, UpdateStepMutationVariables>(UpdateStepDocument, baseOptions);
      }
export type UpdateStepMutationHookResult = ReturnType<typeof useUpdateStepMutation>;
export type UpdateStepMutationResult = ApolloReactCommon.MutationResult<UpdateStepMutation>;
export type UpdateStepMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStepMutation, UpdateStepMutationVariables>;
export const CreateOptionDocument = gql`
    mutation CreateOption($name: String!, $selectorId: Float, $sort: Float!) {
  createOption(name: $name, selectorId: $selectorId, sort: $sort)
}
    `;
export type CreateOptionMutationFn = ApolloReactCommon.MutationFunction<CreateOptionMutation, CreateOptionMutationVariables>;

/**
 * __useCreateOptionMutation__
 *
 * To run a mutation, you first call `useCreateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionMutation, { data, loading, error }] = useCreateOptionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      selectorId: // value for 'selectorId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCreateOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOptionMutation, CreateOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOptionMutation, CreateOptionMutationVariables>(CreateOptionDocument, baseOptions);
      }
export type CreateOptionMutationHookResult = ReturnType<typeof useCreateOptionMutation>;
export type CreateOptionMutationResult = ApolloReactCommon.MutationResult<CreateOptionMutation>;
export type CreateOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOptionMutation, CreateOptionMutationVariables>;
export const FilterOptionsDocument = gql`
    query FilterOptions {
  filterOptions {
    id
    name
    sort
    selector {
      id
      label
      description
      page
      sort
    }
  }
}
    `;

/**
 * __useFilterOptionsQuery__
 *
 * To run a query within a React component, call `useFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFilterOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FilterOptionsQuery, FilterOptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<FilterOptionsQuery, FilterOptionsQueryVariables>(FilterOptionsDocument, baseOptions);
      }
export function useFilterOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilterOptionsQuery, FilterOptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FilterOptionsQuery, FilterOptionsQueryVariables>(FilterOptionsDocument, baseOptions);
        }
export type FilterOptionsQueryHookResult = ReturnType<typeof useFilterOptionsQuery>;
export type FilterOptionsLazyQueryHookResult = ReturnType<typeof useFilterOptionsLazyQuery>;
export type FilterOptionsQueryResult = ApolloReactCommon.QueryResult<FilterOptionsQuery, FilterOptionsQueryVariables>;
export const OptionsDocument = gql`
    query Options {
  options {
    id
    name
    sort
    selector {
      id
      label
    }
  }
}
    `;

/**
 * __useOptionsQuery__
 *
 * To run a query within a React component, call `useOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OptionsQuery, OptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<OptionsQuery, OptionsQueryVariables>(OptionsDocument, baseOptions);
      }
export function useOptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OptionsQuery, OptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OptionsQuery, OptionsQueryVariables>(OptionsDocument, baseOptions);
        }
export type OptionsQueryHookResult = ReturnType<typeof useOptionsQuery>;
export type OptionsLazyQueryHookResult = ReturnType<typeof useOptionsLazyQuery>;
export type OptionsQueryResult = ApolloReactCommon.QueryResult<OptionsQuery, OptionsQueryVariables>;
export const RemoveOptionDocument = gql`
    mutation RemoveOption($id: Float!) {
  removeOption(id: $id)
}
    `;
export type RemoveOptionMutationFn = ApolloReactCommon.MutationFunction<RemoveOptionMutation, RemoveOptionMutationVariables>;

/**
 * __useRemoveOptionMutation__
 *
 * To run a mutation, you first call `useRemoveOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOptionMutation, { data, loading, error }] = useRemoveOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveOptionMutation, RemoveOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveOptionMutation, RemoveOptionMutationVariables>(RemoveOptionDocument, baseOptions);
      }
export type RemoveOptionMutationHookResult = ReturnType<typeof useRemoveOptionMutation>;
export type RemoveOptionMutationResult = ApolloReactCommon.MutationResult<RemoveOptionMutation>;
export type RemoveOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveOptionMutation, RemoveOptionMutationVariables>;
export const UpdateOptionDocument = gql`
    mutation UpdateOption($id: Float!, $name: String!, $selectorId: Float!, $sort: Float!) {
  updateOption(id: $id, name: $name, selectorId: $selectorId, sort: $sort)
}
    `;
export type UpdateOptionMutationFn = ApolloReactCommon.MutationFunction<UpdateOptionMutation, UpdateOptionMutationVariables>;

/**
 * __useUpdateOptionMutation__
 *
 * To run a mutation, you first call `useUpdateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionMutation, { data, loading, error }] = useUpdateOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      selectorId: // value for 'selectorId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUpdateOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOptionMutation, UpdateOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOptionMutation, UpdateOptionMutationVariables>(UpdateOptionDocument, baseOptions);
      }
export type UpdateOptionMutationHookResult = ReturnType<typeof useUpdateOptionMutation>;
export type UpdateOptionMutationResult = ApolloReactCommon.MutationResult<UpdateOptionMutation>;
export type UpdateOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOptionMutation, UpdateOptionMutationVariables>;
export const SavedConfigDocument = gql`
    mutation SavedConfig($uri: String!) {
  config(uri: $uri) {
    id
    name
    collection
    video
    documents
    properties {
      name
      value
    }
    contents {
      id
      type
      content
      align
      sort
    }
    bases {
      id
      name
      collection
    }
  }
}
    `;
export type SavedConfigMutationFn = ApolloReactCommon.MutationFunction<SavedConfigMutation, SavedConfigMutationVariables>;

/**
 * __useSavedConfigMutation__
 *
 * To run a mutation, you first call `useSavedConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavedConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savedConfigMutation, { data, loading, error }] = useSavedConfigMutation({
 *   variables: {
 *      uri: // value for 'uri'
 *   },
 * });
 */
export function useSavedConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SavedConfigMutation, SavedConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<SavedConfigMutation, SavedConfigMutationVariables>(SavedConfigDocument, baseOptions);
      }
export type SavedConfigMutationHookResult = ReturnType<typeof useSavedConfigMutation>;
export type SavedConfigMutationResult = ApolloReactCommon.MutationResult<SavedConfigMutation>;
export type SavedConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<SavedConfigMutation, SavedConfigMutationVariables>;
export const CreateSavedConfigDocument = gql`
    mutation CreateSavedConfig($name: String!, $config: Float!) {
  createSavedConfig(name: $name, config: $config) {
    name
    uri
  }
}
    `;
export type CreateSavedConfigMutationFn = ApolloReactCommon.MutationFunction<CreateSavedConfigMutation, CreateSavedConfigMutationVariables>;

/**
 * __useCreateSavedConfigMutation__
 *
 * To run a mutation, you first call `useCreateSavedConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedConfigMutation, { data, loading, error }] = useCreateSavedConfigMutation({
 *   variables: {
 *      name: // value for 'name'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCreateSavedConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSavedConfigMutation, CreateSavedConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSavedConfigMutation, CreateSavedConfigMutationVariables>(CreateSavedConfigDocument, baseOptions);
      }
export type CreateSavedConfigMutationHookResult = ReturnType<typeof useCreateSavedConfigMutation>;
export type CreateSavedConfigMutationResult = ApolloReactCommon.MutationResult<CreateSavedConfigMutation>;
export type CreateSavedConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSavedConfigMutation, CreateSavedConfigMutationVariables>;
export const RemoveSavedConfigDocument = gql`
    mutation RemoveSavedConfig($id: String!) {
  removeSavedConfig(id: $id)
}
    `;
export type RemoveSavedConfigMutationFn = ApolloReactCommon.MutationFunction<RemoveSavedConfigMutation, RemoveSavedConfigMutationVariables>;

/**
 * __useRemoveSavedConfigMutation__
 *
 * To run a mutation, you first call `useRemoveSavedConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSavedConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSavedConfigMutation, { data, loading, error }] = useRemoveSavedConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSavedConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSavedConfigMutation, RemoveSavedConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSavedConfigMutation, RemoveSavedConfigMutationVariables>(RemoveSavedConfigDocument, baseOptions);
      }
export type RemoveSavedConfigMutationHookResult = ReturnType<typeof useRemoveSavedConfigMutation>;
export type RemoveSavedConfigMutationResult = ApolloReactCommon.MutationResult<RemoveSavedConfigMutation>;
export type RemoveSavedConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSavedConfigMutation, RemoveSavedConfigMutationVariables>;
export const RemoveSavedConfigsDocument = gql`
    mutation RemoveSavedConfigs($ids: [String!]!) {
  removeSavedConfigs(ids: $ids)
}
    `;
export type RemoveSavedConfigsMutationFn = ApolloReactCommon.MutationFunction<RemoveSavedConfigsMutation, RemoveSavedConfigsMutationVariables>;

/**
 * __useRemoveSavedConfigsMutation__
 *
 * To run a mutation, you first call `useRemoveSavedConfigsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSavedConfigsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSavedConfigsMutation, { data, loading, error }] = useRemoveSavedConfigsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveSavedConfigsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSavedConfigsMutation, RemoveSavedConfigsMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSavedConfigsMutation, RemoveSavedConfigsMutationVariables>(RemoveSavedConfigsDocument, baseOptions);
      }
export type RemoveSavedConfigsMutationHookResult = ReturnType<typeof useRemoveSavedConfigsMutation>;
export type RemoveSavedConfigsMutationResult = ApolloReactCommon.MutationResult<RemoveSavedConfigsMutation>;
export type RemoveSavedConfigsMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSavedConfigsMutation, RemoveSavedConfigsMutationVariables>;
export const SavedConfigsDocument = gql`
    query SavedConfigs {
  savedConfigs {
    id
    name
    uri
    config
  }
}
    `;

/**
 * __useSavedConfigsQuery__
 *
 * To run a query within a React component, call `useSavedConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedConfigsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SavedConfigsQuery, SavedConfigsQueryVariables>) {
        return ApolloReactHooks.useQuery<SavedConfigsQuery, SavedConfigsQueryVariables>(SavedConfigsDocument, baseOptions);
      }
export function useSavedConfigsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SavedConfigsQuery, SavedConfigsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SavedConfigsQuery, SavedConfigsQueryVariables>(SavedConfigsDocument, baseOptions);
        }
export type SavedConfigsQueryHookResult = ReturnType<typeof useSavedConfigsQuery>;
export type SavedConfigsLazyQueryHookResult = ReturnType<typeof useSavedConfigsLazyQuery>;
export type SavedConfigsQueryResult = ApolloReactCommon.QueryResult<SavedConfigsQuery, SavedConfigsQueryVariables>;
export const SendConfigsDocument = gql`
    mutation SendConfigs($ids: [String!]!) {
  sendConfigs(ids: $ids)
}
    `;
export type SendConfigsMutationFn = ApolloReactCommon.MutationFunction<SendConfigsMutation, SendConfigsMutationVariables>;

/**
 * __useSendConfigsMutation__
 *
 * To run a mutation, you first call `useSendConfigsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfigsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfigsMutation, { data, loading, error }] = useSendConfigsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSendConfigsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendConfigsMutation, SendConfigsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendConfigsMutation, SendConfigsMutationVariables>(SendConfigsDocument, baseOptions);
      }
export type SendConfigsMutationHookResult = ReturnType<typeof useSendConfigsMutation>;
export type SendConfigsMutationResult = ApolloReactCommon.MutationResult<SendConfigsMutation>;
export type SendConfigsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendConfigsMutation, SendConfigsMutationVariables>;
export const CreateSelectionDocument = gql`
    mutation CreateSelection($label: String!, $description: String!, $sort: Float!, $page: Boolean!) {
  createSelection(label: $label, description: $description, sort: $sort, page: $page)
}
    `;
export type CreateSelectionMutationFn = ApolloReactCommon.MutationFunction<CreateSelectionMutation, CreateSelectionMutationVariables>;

/**
 * __useCreateSelectionMutation__
 *
 * To run a mutation, you first call `useCreateSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelectionMutation, { data, loading, error }] = useCreateSelectionMutation({
 *   variables: {
 *      label: // value for 'label'
 *      description: // value for 'description'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCreateSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSelectionMutation, CreateSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSelectionMutation, CreateSelectionMutationVariables>(CreateSelectionDocument, baseOptions);
      }
export type CreateSelectionMutationHookResult = ReturnType<typeof useCreateSelectionMutation>;
export type CreateSelectionMutationResult = ApolloReactCommon.MutationResult<CreateSelectionMutation>;
export type CreateSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSelectionMutation, CreateSelectionMutationVariables>;
export const CreateSelectionsDocument = gql`
    mutation CreateSelections($config: String!) {
  createSelections(config: $config)
}
    `;
export type CreateSelectionsMutationFn = ApolloReactCommon.MutationFunction<CreateSelectionsMutation, CreateSelectionsMutationVariables>;

/**
 * __useCreateSelectionsMutation__
 *
 * To run a mutation, you first call `useCreateSelectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSelectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSelectionsMutation, { data, loading, error }] = useCreateSelectionsMutation({
 *   variables: {
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCreateSelectionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSelectionsMutation, CreateSelectionsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSelectionsMutation, CreateSelectionsMutationVariables>(CreateSelectionsDocument, baseOptions);
      }
export type CreateSelectionsMutationHookResult = ReturnType<typeof useCreateSelectionsMutation>;
export type CreateSelectionsMutationResult = ApolloReactCommon.MutationResult<CreateSelectionsMutation>;
export type CreateSelectionsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSelectionsMutation, CreateSelectionsMutationVariables>;
export const RemoveSelectionDocument = gql`
    mutation RemoveSelection($id: Float!) {
  removeSelection(id: $id)
}
    `;
export type RemoveSelectionMutationFn = ApolloReactCommon.MutationFunction<RemoveSelectionMutation, RemoveSelectionMutationVariables>;

/**
 * __useRemoveSelectionMutation__
 *
 * To run a mutation, you first call `useRemoveSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSelectionMutation, { data, loading, error }] = useRemoveSelectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSelectionMutation, RemoveSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSelectionMutation, RemoveSelectionMutationVariables>(RemoveSelectionDocument, baseOptions);
      }
export type RemoveSelectionMutationHookResult = ReturnType<typeof useRemoveSelectionMutation>;
export type RemoveSelectionMutationResult = ApolloReactCommon.MutationResult<RemoveSelectionMutation>;
export type RemoveSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSelectionMutation, RemoveSelectionMutationVariables>;
export const SelectionsDocument = gql`
    query Selections {
  selections {
    id
    label
    description
    page
    sort
  }
}
    `;

/**
 * __useSelectionsQuery__
 *
 * To run a query within a React component, call `useSelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectionsQuery, SelectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectionsQuery, SelectionsQueryVariables>(SelectionsDocument, baseOptions);
      }
export function useSelectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectionsQuery, SelectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectionsQuery, SelectionsQueryVariables>(SelectionsDocument, baseOptions);
        }
export type SelectionsQueryHookResult = ReturnType<typeof useSelectionsQuery>;
export type SelectionsLazyQueryHookResult = ReturnType<typeof useSelectionsLazyQuery>;
export type SelectionsQueryResult = ApolloReactCommon.QueryResult<SelectionsQuery, SelectionsQueryVariables>;
export const UpdateSelectionDocument = gql`
    mutation UpdateSelection($id: Int!, $label: String!, $description: String!, $sort: Float!, $page: Boolean!) {
  updateSelection(id: $id, label: $label, description: $description, sort: $sort, page: $page)
}
    `;
export type UpdateSelectionMutationFn = ApolloReactCommon.MutationFunction<UpdateSelectionMutation, UpdateSelectionMutationVariables>;

/**
 * __useUpdateSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelectionMutation, { data, loading, error }] = useUpdateSelectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      label: // value for 'label'
 *      description: // value for 'description'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUpdateSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSelectionMutation, UpdateSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSelectionMutation, UpdateSelectionMutationVariables>(UpdateSelectionDocument, baseOptions);
      }
export type UpdateSelectionMutationHookResult = ReturnType<typeof useUpdateSelectionMutation>;
export type UpdateSelectionMutationResult = ApolloReactCommon.MutationResult<UpdateSelectionMutation>;
export type UpdateSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSelectionMutation, UpdateSelectionMutationVariables>;
export const SettingsDocument = gql`
    query Settings {
  settings {
    id
    name
    value
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
      }
export function useSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = ApolloReactCommon.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const UpdateSettingDocument = gql`
    mutation UpdateSetting($id: Float!, $value: String!) {
  updateSetting(id: $id, value: $value)
}
    `;
export type UpdateSettingMutationFn = ApolloReactCommon.MutationFunction<UpdateSettingMutation, UpdateSettingMutationVariables>;

/**
 * __useUpdateSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingMutation, { data, loading, error }] = useUpdateSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSettingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSettingMutation, UpdateSettingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSettingMutation, UpdateSettingMutationVariables>(UpdateSettingDocument, baseOptions);
      }
export type UpdateSettingMutationHookResult = ReturnType<typeof useUpdateSettingMutation>;
export type UpdateSettingMutationResult = ApolloReactCommon.MutationResult<UpdateSettingMutation>;
export type UpdateSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSettingMutation, UpdateSettingMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    refreshToken
    user {
      id
      name
      lastname
      email
      dsgvo
      role
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    lastname
    email
    dsgvo
    role
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($name: String!, $lastname: String!, $email: String!, $dsgvo: Boolean!, $password: String!, $role: String!) {
  register(name: $name, lastname: $lastname, email: $email, dsgvo: $dsgvo, password: $password, role: $role)
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      dsgvo: // value for 'dsgvo'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: Int!) {
  removeUser(id: $id)
}
    `;
export type RemoveUserMutationFn = ApolloReactCommon.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = ApolloReactCommon.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const RevokeTokenDocument = gql`
    mutation RevokeToken($userId: Int!) {
  revokeRefreshTokensForUser(userId: $userId)
}
    `;
export type RevokeTokenMutationFn = ApolloReactCommon.MutationFunction<RevokeTokenMutation, RevokeTokenMutationVariables>;

/**
 * __useRevokeTokenMutation__
 *
 * To run a mutation, you first call `useRevokeTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTokenMutation, { data, loading, error }] = useRevokeTokenMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRevokeTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeTokenMutation, RevokeTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeTokenMutation, RevokeTokenMutationVariables>(RevokeTokenDocument, baseOptions);
      }
export type RevokeTokenMutationHookResult = ReturnType<typeof useRevokeTokenMutation>;
export type RevokeTokenMutationResult = ApolloReactCommon.MutationResult<RevokeTokenMutation>;
export type RevokeTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeTokenMutation, RevokeTokenMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($id: Int!, $role: String!) {
  updateRole(id: $id, role: $role)
}
    `;
export type UpdateRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = ApolloReactCommon.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $name: String!, $lastname: String!, $email: String!, $dsgvo: Boolean!) {
  updateUser(id: $id, name: $name, lastname: $lastname, email: $email, dsgvo: $dsgvo)
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      dsgvo: // value for 'dsgvo'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateVerifyDocument = gql`
    mutation UpdateVerify($id: Int!, $verified: Boolean!) {
  updateVerify(id: $id, verified: $verified)
}
    `;
export type UpdateVerifyMutationFn = ApolloReactCommon.MutationFunction<UpdateVerifyMutation, UpdateVerifyMutationVariables>;

/**
 * __useUpdateVerifyMutation__
 *
 * To run a mutation, you first call `useUpdateVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVerifyMutation, { data, loading, error }] = useUpdateVerifyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useUpdateVerifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVerifyMutation, UpdateVerifyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateVerifyMutation, UpdateVerifyMutationVariables>(UpdateVerifyDocument, baseOptions);
      }
export type UpdateVerifyMutationHookResult = ReturnType<typeof useUpdateVerifyMutation>;
export type UpdateVerifyMutationResult = ApolloReactCommon.MutationResult<UpdateVerifyMutation>;
export type UpdateVerifyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVerifyMutation, UpdateVerifyMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    name
    lastname
    email
    verified
    role
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const VerifyUserDocument = gql`
    query VerifyUser($token: String!) {
  verifyUser(token: $token)
}
    `;

/**
 * __useVerifyUserQuery__
 *
 * To run a query within a React component, call `useVerifyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyUserQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyUserQuery, VerifyUserQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyUserQuery, VerifyUserQueryVariables>(VerifyUserDocument, baseOptions);
      }
export function useVerifyUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyUserQuery, VerifyUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyUserQuery, VerifyUserQueryVariables>(VerifyUserDocument, baseOptions);
        }
export type VerifyUserQueryHookResult = ReturnType<typeof useVerifyUserQuery>;
export type VerifyUserLazyQueryHookResult = ReturnType<typeof useVerifyUserLazyQuery>;
export type VerifyUserQueryResult = ApolloReactCommon.QueryResult<VerifyUserQuery, VerifyUserQueryVariables>;
import React, { ReactNode, useContext, useState } from 'react'
import Modal from 'antd/es/modal';
import styles from '../actions.module.scss';
import { DataContext } from '../../../Context/DataContext';
import Login from '../../Login';

interface ButtonProps {
  label: string
  icon: ReactNode
  title: string
  checkLogin?: boolean
  active?: boolean
}

export const BaseButton: React.FC<ButtonProps> = ({ label, icon, children, title, checkLogin = true, active = false }) => {
  const [open, setOpen] = useState(false);
  const { me } = useContext(DataContext);
  const actualWidth = document.body.clientWidth;

  return (
    <>
      <button className={styles.actionButton} onClick={() => setOpen(o => !o)}>
        {icon}
        {label}
      </button>

      <Modal
        width={actualWidth < 800 ? (actualWidth - 32) : (!me && checkLogin ? "auto" : 800)}
        title={<h3 className={styles.headline}>{title}</h3>}
        visible={active ? active : open}
        centered
        destroyOnClose={true}
        maskClosable={true}
        maskStyle={{ backgroundColor: 'rgba(0, 41, 38, 0.7)' }}
        closable={true}
        onCancel={() => setOpen(false)}
        footer={null}
        bodyStyle={{ padding: !me && checkLogin ? 0 : "1rem" }}
      >

        {!me && checkLogin ?
          (
            <Login close={() => { }} />
          ) : (
            children
          )
        }
      </Modal>
    </>
  );
}
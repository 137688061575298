import React from 'react';
import styles from './verify.module.scss';
import { useParams } from 'react-router-dom';
import Footer from '../../Components/Footer';
import { Button } from '../../Components/Button';
import { useVerifyUserQuery } from '../../generated/graphql';
import Loader from '../../Components/Loader';

const Verify: React.FC = () => {
  // @ts-ignore
  const { token } = useParams()
  const { loading } = useVerifyUserQuery({ variables: { token } });

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <img className={styles.bgImg} src="/intro.jpg" alt="background" />
        <div className={styles.verifyContainer}>
          {loading ? <Loader /> : <p>Your account was successfully verified!<br />You can now login to your account.</p>}
        </div>
      </div>
      <Footer refresh={() => { }} >
        <Button path={`/preselection`} label={"Let's go!"} />
      </Footer>
    </div>
  );
}

export default Verify;
import React, { useState, Suspense, useEffect } from 'react'
import styles from './styles.module.scss'
import { useCollectionsQuery, useOptionsQuery, useCreateConfigurationMutation, useUpdateConfigMutation, useBasesWithoutRelationQuery, Configuration } from '../../../generated/graphql';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import { useSpring, animated } from 'react-spring';
import Row from 'antd/es/row';
import Divider from 'antd/es/divider';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Form from 'antd/es/form';
import { notification } from 'antd';
import Loader from '../../../Components/Loader';
import { Content, ContentType } from '../Content';
import Title from 'antd/es/typography/Title';
import { Image } from '../../../Components/Image';
import { SimpleFileEditor } from '../../../Components/Editors/SimpleFileEditor';
import { Documents } from './Documents';

const { Option } = Select;

type ConfigurationState = {
  [key: string]: string | undefined
}

type Selector = {
  id: number,
  label: string,
  sort: number
}

type OptionType = {
  id: number,
  name: string,
  sort: number,
  selector: Selector
}

type LabelWithOptions = {
  label: string,
  options: OptionType[]
}

type createProps = {
  openCreate: boolean,
  fetchConfig: Function,
  config?: Configuration
}

export const CreateConfig: React.FC<createProps> = ({ openCreate, fetchConfig, config }) => {
  let initialContents = [];
  let initialProperties = {};
  let initialBases = [];

  if (config && config.contents) {
    initialContents = config.contents.sort((a: any, b: any) => a.sort - b.sort)
    initialContents.map(c => {
      delete c.__typename;
      return c;
    })
  }

  if (config && config.properties) {
    config.properties.forEach(prop => {
      initialProperties[prop.name] = prop.value;
    })
  }

  if (config && config.bases) {
    config.bases.forEach(base => {
      initialBases.push(base.id)
    })
  }

  const [createConfig] = useCreateConfigurationMutation();
  const [updateConfig] = useUpdateConfigMutation();
  const [video, setVideo] = useState<string>(null);
  const [documents, setDocuments] = useState<string[]>([]);
  const [document, setDocument] = useState<string>(null);
  const [form] = Form.useForm();
  const [contents, setContents] = useState<ContentType[]>(initialContents);
  const [configState, setConfigState] = useState<ConfigurationState>(initialProperties);
  const [types, setTypes] = useState<LabelWithOptions[]>();
  const { data: collectionData, loading: loadingCollections } = useCollectionsQuery();
  const { data: optionsData } = useOptionsQuery();
  const { data: basesData, loading: loadingBases, refetch } = useBasesWithoutRelationQuery({ fetchPolicy: 'network-only' });
  const ani = useSpring({
    maxHeight: openCreate ? 4000 : 0
  });

  useEffect(() => {
    if (optionsData && optionsData.options) {
      const op = optionsData.options.reduce<LabelWithOptions[]>((acc: any, d: any) => {
        const type = d.selector.label;
        if (!acc[type]) {
          acc[type] = [];
          acc[type]["label"] = type;
          acc[type]["options"] = []
        }
        acc[type]["options"].push(d as OptionType[])
        return acc;
      }, [] as LabelWithOptions[]);

      setTypes(op);
    }
  }, [optionsData])

  useEffect(() => {
    refetch();
    if (config && config.video) {
      setVideo(config.video)
    }
    if (config && config.documents) {
      setDocuments(config.documents)
    }
  }, [config]) // eslint-disable-line


  const handleSelects = (val: string) => {
    if (optionsData) {
      const { options } = optionsData && optionsData;
      const selectedOption = options.find(o => o.id === parseInt(val))
      if (selectedOption) {
        setConfigState({
          ...configState,
          [selectedOption.selector.label]: selectedOption.name
        })
      }
    }
  }

  const handleSubmit = async values => {
    const { name, collection, bases } = values;
    const properties = [];
    for (const [key, value] of Object.entries(configState)) {
      properties.push({ name: key, value })
    }

    const configuration = await createConfig({
      variables: {
        name,
        collection,
        properties,
        contents,
        bases,
        video,
        documents
      }
    });

    if (configuration.data.createConfiguration) {
      setConfigState({})
      setContents([]);
      form.resetFields();
      fetchConfig();
      notification.success({
        message: `Configuration name "${name}" created!`
      })
    } else {
      notification.error({
        message: `Error with "${name}"`,
        description: `Configuration name "${name}" alredy exists!\n Please use another name.`
      })
    }
  }

  const handleUpdate = async values => {
    const { name, collection, bases } = values;
    const properties = [];
    for (const [key, value] of Object.entries(configState)) {
      properties.push({ name: key, value });
    }

    const configuration = await updateConfig({
      variables: {
        id: config.id,
        name,
        collection,
        video,
        documents,
        properties,
        contents,
        bases
      }
    });

    if (configuration.data.updateConfiguration) {
      fetchConfig();
      notification.success({
        message: `Configuration name "${name}" updated!`
      })
    } else {
      notification.error({
        message: `Error with "${name}"`,
        description: `Could not update "${name}".`
      })
    }
  }

  useEffect(() => {
    if (document) {
      setDocuments((ds) => {
        const newVal = [
          ...ds,
          document
        ]
        setDocument(null);
        return newVal;
      });
    }
  }, [document]);

  return (
    <animated.div style={ani} className={styles.createContainer}>
      <Form
        name="basic"
        initialValues={config && config.name ? { name: config.name, collection: config.collection, bases: initialBases } : { name: "", collection: undefined, bases: [] }}
        onFinish={config ? handleUpdate : handleSubmit}
        form={form}
      >
        <Row className={styles.mainRow} gutter={16}>
          <Col flex={"50%"}>
            <Form.Item
              name={"name"}
              rules={[{ required: true, message: 'Please input the name!' }]}
              className={styles.formItem}
            >
              <Input
                size={"large"}
                autoComplete={"off"}
                allowClear
                placeholder={"Configuration Name"}
              />
            </Form.Item>
          </Col>
          <Col flex={"50%"}>
            <Suspense fallback={<Loader />}>
              <Form.Item
                name={"collection"}
                rules={[{ required: true, message: 'Please select the collection!' }]}
                className={styles.formItem}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  size="large"
                  allowClear
                  disabled={loadingCollections}
                  placeholder="Select the Collection"
                  optionFilterProp={"children"}
                  optionLabelProp="label"
                  className={styles.select}
                  filterOption={(input, option) => option!.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {collectionData && collectionData.collections.map((col, idx) => (
                    <Option key={idx} value={col.name} label={<span className={styles.activeLabel}>{col.name}</span>}>
                      <span className={styles.optionImage}>
                        <Image uri={col.image} name={col.name} />
                      </span>
                      <span className={styles.optionLabel}>{col.name}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Suspense>
          </Col>
        </Row>
        <Row className={styles.mainRow} gutter={16}>
          <Suspense fallback={<Loader />}>
            {types && Object.entries(types).map(([index, { label, options }]) => (
              <Col key={index} flex={"50%"}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  size="large"
                  allowClear
                  disabled={loadingCollections}
                  value={configState[label]}
                  placeholder={`Select for ${label}`}
                  onChange={handleSelects}
                  optionFilterProp={"children"}
                  className={styles.select}
                  filterOption={(input, option) => option!.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {options.map((option, idx) => (
                    <Option key={idx} value={option.id}>{option.name}</Option>
                  ))}
                </Select>
              </Col>
            ))}
          </Suspense>
        </Row>
        <Row className={styles.mainRow} gutter={16}>
          <Suspense fallback={<Loader />}>
            <Col flex={"50%"}>
              <Title level={4} style={{ color: '#003363' }}>Select Bases</Title>
              <Form.Item
                name={"bases"}
                rules={[{ required: true, message: 'Please select the bases!' }]}
                className={styles.formItem}
              >
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: "100%" }}
                  size="large"
                  allowClear
                  disabled={loadingBases}
                  placeholder="Select the Bases"
                  optionFilterProp={"children"}
                  optionLabelProp="children"
                  className={styles.select}

                >
                  {basesData && basesData.basesWithoutRelation.map(base => (
                    <Option key={base.id} label={base.name} value={base.id}>{base.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Suspense>
        </Row>
        <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
        <Row>
          <Col flex={"50%"} style={{ paddingRight: "1rem" }}>
            <h4 className={[styles.headline, styles.bold].join(' ')}>Video</h4>
            <SimpleFileEditor type={'videos'} set={setVideo} value={video} />
          </Col>

          <Col flex={"50%"} style={{ paddingLeft: "1rem" }}>
            <h4 className={[styles.headline, styles.bold].join(' ')}>Documents</h4>
            <Documents docs={documents} set={setDocuments} />
            <SimpleFileEditor type={'documents'} set={setDocument} value={document} />
          </Col>
        </Row>
        <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
        <Content contents={contents} set={setContents} />
        <Button type={"primary"} htmlType="submit">Save</Button>
      </Form>
      <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
    </animated.div>
  );
}
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Typography, Button } from 'antd';
import {
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import CKEditor from '@ckeditor/ckeditor5-react';
import config from './config.json';

const { Paragraph } = Typography;

interface DescEditorProps {
  text: string
  setDescription: Dispatch<SetStateAction<string>>
  description: string
  update: Function,
  record: Selection
}

export const DescEditor: React.FC<DescEditorProps> = ({ text, setDescription, description, update, record }) => {
  const [openEditor, setOpenEditor] = useState(false);

  return (
    <div style={{ display: 'flex' }}>
      {!openEditor ?
        (
          <Paragraph
            ellipsis
            style={{ padding: "0 .6rem", marginBottom: 0 }}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </Paragraph>
        ) : (
          <CKEditor
            editor={BalloonEditor}
            focus={true}
            data={text}
            config={config}
            height={300}
            onInit={editor => {
              setDescription(editor.getData());
              editor.editing.view.change(writer => {
                writer.setStyle(
                  "height",
                  "300px",
                  editor.editing.view.document.getRoot()
                );
                writer.setStyle(
                  "width",
                  "100%",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setDescription(data);
            }}
          />
        )
      }
      <Button type="link">
        {openEditor && (
          <SaveOutlined
            translate={"edit"}
            onClick={() => {
              update("description", description, record);
              setOpenEditor(!openEditor);
            }}
          />
        )}
        {!openEditor && <EditOutlined translate={"edit"} onClick={() => setOpenEditor(!openEditor)} />}
      </Button>
    </div>
  );
}
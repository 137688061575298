import { notification } from 'antd';
import React, { useState } from 'react';
import { useCreateSavedConfigMutation } from '../../generated/graphql';
import { Button } from '../Button';
import Input from '../Input';

import styles from './styles.module.scss';

export const Save: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [config] = useState(localStorage.getItem("config") ? JSON.parse(localStorage.getItem("config")) : null);
  const [saved, setSaved] = useState<boolean>(false);
  const [saveConfig] = useCreateSavedConfigMutation();

  const handleSave = async () => {
    const savedConfig = await saveConfig({
      variables: {
        name,
        config: config.id
      }
    })
    if (savedConfig && savedConfig.data) {
      notification.success({
        message: "Successfully saved Configuration."
      });
      setSaved(true);
    } else {
      notification.error({
        message: "Could not save Configuration!"
      });
    }
  }

  return (
    <div className={styles.container}>
      {saved ?
        (
          <>
            <p>Your Configuration <strong>{name}</strong> was saved.</p>
            <p>To check all your saved Configurations please open <strong>my List</strong>.</p>
          </>
        ) : (
          <>
            <Input
              id="name"
              value={name}
              placeholder={"Name your Configuration ..."}
              type={"text"}
              setValue={setName}
            />

            <div className={styles.saveButton}>
              <Button label={"Save"} onClick={handleSave} selfAlign={false} />
            </div>
          </>
        )
      }
    </div>
  );
}
import React from 'react';
import { Editor } from '../../Components/Editors/Editor';
import { v4 as uuidv4 } from 'uuid';
import styles from './config.module.scss';
import { AddFab } from '../../Components/Fab';
import arrayMove from 'array-move';
import { Sortable } from '../../Components/Sortable/Container';
import { SortableItem } from '../../Components/Sortable/Item';
import { FileEditor } from '../../Components/Editors/FileEditor';
import Title from 'antd/es/typography/Title';

export type ContentType = {
  id: string
  type: string
  content: string
  align: string
  sort: number
}

interface ContentProps {
  contents: ContentType[],
  set: Function
}

export const Content: React.FC<ContentProps> = ({ contents, set }) => {
  const handleNewContent = (type) => {
    set([
      ...contents,
      {
        id: uuidv4(),
        type,
        content: type === 'headline' ? '<h2></h2>' : '',
        align: type === 'headline' ? 'full' : 'left',
        sort: contents.length > 0 ? contents.length : 0
      } as ContentType
    ])
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const newSorted = arrayMove(contents, oldIndex, newIndex);
    newSorted.map((item, i) => {
      if (item.sort !== i) {
        item.sort = i
      }
      return item;
    });

    set(c => ([
      ...newSorted,
    ]));
  };

  return (
    <div className={styles.contents}>
      <Title level={4} className={styles.headline}>Contents <AddFab action={handleNewContent} /></Title>
      <Sortable
        onSortEnd={handleSortEnd}
        useDragHandle
        axis={"xy"}
      >
        {contents.length > 0 && contents.map((c, i) => {
          if (c.type === 'headline' || c.type === 'text') {
            return <SortableItem key={c.id} index={i} align={c.align}><Editor contentObj={c} index={i} set={set} /></SortableItem>;
          } else if (c.type === 'videos' || c.type === 'images') {
            return <SortableItem key={c.id} index={i} align={c.align}><FileEditor contentObj={c} index={i} set={set}>Image</FileEditor></SortableItem>;
          } else {
            return <SortableItem key={c.id} index={i} align={c.align}><Editor contentObj={c} index={i} set={set} /></SortableItem>;
          }
        })}
      </Sortable>
    </div>
  );
}
import React, { useState } from 'react';
import { ContentType } from '../../Admin/Components/Content';
import styles from './fileEditor.module.scss';
import { Image } from '../Image';
import { Video } from '../Video';
import { Dummy } from '../Dummy';
import { EditFab } from './EditFab';
import Modal from 'antd/es/modal';
import { FileHandler } from '../../Admin/Components/FileHandler';


interface FileEditorProps {
  contentObj: ContentType,
  index: number,
  set: Function
}

export const FileEditor: React.FC<FileEditorProps> = ({ contentObj, index, set }) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let Expose;

  const handleContent = (data: string) => {
    setOpen(false)
    const content = {
      ...contentObj,
      content: data
    }

    set(c => {
      c[index] = content;
      return [...c]
    })
  }

  const handleAlign = (align: string) => {
    setOpen(false)
    const content = {
      ...contentObj,
      align
    }

    set(c => {
      c[index] = content;
      return [...c]
    })
  }

  const handleRemove = () => {
    setOpen(false)
    set(c => {
      return [...c.filter((it) => it.id !== contentObj.id)];
    })
  }

  if (contentObj.content.length > 0) {
    if (contentObj.type === 'images') {
      Expose = Image;
    }
    if (contentObj.type === 'videos') {
      Expose = Video;
    }
  }

  return (
    <>
      <span className={styles.alignHandle}>
        <EditFab action={handleAlign} align={contentObj.align} open={open} setOpen={setOpen} remove={handleRemove} />
      </span>
      <div className={styles.editor}>

        {contentObj.content.length > 0 ? (
          <Expose uri={contentObj.content} name={contentObj.content} />
        ) : (
            <Dummy open={() => setOpenModal(!openModal)} />
          )}

        <Modal
          title={`Select an ${contentObj.type} File`}
          visible={openModal}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          centered
          width={1024}
        >
          <FileHandler type={contentObj.type} setFile={handleContent} selected={contentObj.content} />
        </Modal>
      </div>
    </>
  );
}